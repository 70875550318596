/* eslint-disable max-len */
export const itIT = {
    module: {
        disabled: `Module disabled, contact Zmenu Team to enable it.`,
    },
    company_info: {
        open_now: `Aberto agora`,
        closed_now: `Fechado agora`,
        more_info: `Mais informações`,
    },
    dialogs: {
        attention: `Attention!`,
        success: `Success!`,
        confirm: `Si`,
        submit: `Invia`,
        edit: `Edit`,
        fail: `Ops!`,
        cancel: `Cancel`,
        confirmation: `Conferma`,
        gone_wrong: `Qualcosa è andato storto, prova ancora!`,
        not_allowed: `Questo utente non è autorizzato a utilizzare questo modulo.`,
        invalid_login: `Login o password non validi, controlla e riprova.`,
        same_user: `Un account con l'e-mail {{ email }} esiste già. Prova ad accedere con questo account, invece di associare questo account social.`,
    },
    login: {
        email: `E-mail`,
        username: `Utente`,
        password: `Password`,
        submit: `Invia`,
        forgot_password: `Ha dimenticato la password?`,
        signup: `Registrati`,
        sigin_google: `Entra con Google`,
        sigin_facebook: `Entra con Facebook`,
    },
    password_scores: {
        very_weak: `Troppo semplice`,
        weak: `Debole`,
        okay: `Accettabile`,
        good: `Buona`,
        strong: `Sicura`,
    },
    signup: {
        title: `Cadastre-se`,
        complete: `Completa il tuo profilo`,
        name: `Nome e Cognome`,
        phone: `Telefono / Cellulare`,
        password: `Conferma la password scelta`,
        confirm_password: `Conferma password`,
        submit: `Registrati`,
        email: `Email de contacto`,
    },
    all: `All`,
    logout: `Disconnettersi`,
    loading: `Caricamento in corso`,
    footer: `fatto con amore da ZMenu ©`,
    dashboard: {
        title: `ZMenu Pannello di controllo`,
        clear_filter: `Cancella ricerca e filtri`,
        highlights: `Evidenziare`,
        not_found: `Non abbiamo trovato nessun piatto secondo la tua ricerca.`,
        no_dishes: `Non ci sono piatti nel menu di oggi.`,
        seach_dish: `Cerca il tuo piatto`,
        search: `Ricerca`,
        menu: {
            menu: `Menù`,
            foods: `Elemento`,
            categories: `Categoria`,
            tags: `Tags`,
            discounts: `Desconto`,
            users: `Utente`,
            profile: `Perfil`,
            reader: `Lettore di QR`,
            company: `Società`,
            bookings: `Riservas`,
        },
        foods: {
            title: `Lista di Elementi`,
            new: `Nuovo elementi`,
            not_found: `Articolo non trovato, selezionane un altro e riprova!`,
            image: {
                maxsize: `Superata la dimensione massima dell'immagine, carica una nuova immagine di massimo 5MB.`,
                error: `Qualcosa è andato storto durante il caricamento dell'immagine, riprova.`,
                remove: `Qualcosa è andato storto con la rimozione dell'immagine, per favore riprova.`,
            },
            filters: {
                search: `Ricerca`,
                weekdays: `Giorni della settimana`,
                periods: `Periodi`,
                inclusive: `Inclusive`,
                preview: `Menù di anteprima`,
                categories: `Categorias`,
                status: `Item Status`,
                featured: `Evidenziato`,
                select_one_preview: `Selezionane uno per abilitare l'anteprima del menu`,
                select_only_one_preview: `Select only one to enable menu preview`,
                select_one_order: `Selezionare solo uno per abilitare l'anteprima del menu`,
                select_only_one_order: `Selezionare solo uno per abilitare l'ordinamento`,
            },
            weekdays: {
                all: `Tutti`,
                sunday: `Domenica`,
                monday: `Lunerdi`,
                tuesday: `Martedi`,
                wednesday: `Mercolidi`,
                thursday: `Giovedi`,
                friday: `Venerdi`,
                saturday: `Sabato`,
            },
            periods: {
                all: `Tutti`,
                coffee: `caffè`,
                lunch: `pranzo`,
                snack: `sputino`,
                dine: `cena`,
            },
            status: {
                all: `Tutti`,
                enabled: `Abilitare`,
                disabled: `Disattivare`,
            },
            featured: {
                all: `Tutti`,
                featured: `Evidenziato`,
                not_featured: `Non evidenziato`,
            },
            columns: {
                name: `Nome`,
                description: `Descrizione`,
                langs: `Lingue registrate`,
                price: `Prezzo`,
                categories: `Categorias`,
                weekdays: `Giorni della settimana`,
                periods: `Periodi`,
                actions: `Azioni`,
            },
            actions: {
                disable: `Disattivare`,
                enable: `Abilitare`,
                price: `Modifica prezzo`,
                edit: `Modifica`,
                delete: `Elimina`,
            },
            dialogs: {
                enable: {
                    title: `Abilitare elemento`,
                    message: `Vuoi davvero abilitare l'elemento '{{food_name}}' ?`,
                },
                disable: {
                    title: `Disattivare elemento`,
                    message: `Vuoi davvero disattivare l'elemento '{{food_name}}' ?`,
                },
                price: `Modifica Prezzo`,
                delete: {
                    title: `Elimina elemento`,
                    message: `Vuoi davvero eliminare l'elemento '{{food_name}}' ?`,
                },
            },
            form: {
                create_title: `Nuovo Elemento`,
                edit_title: `Modifica elemento`,
                image: `Elemento immagine`,
                change_image: `Cambia immagine`,
                code: `Codice`,
                order: `Ordine`,
                enabled: `Abilitare`,
                featured: `Evidenziato`,
                price: `Prezzo`,
                promo_price: `Prezzo promozionale`,
                categories: `Categorias`,
                add_category: `Aggiungi nuova categoria`,
                tags: `Tags`,
                add_tag: `Aggiungi nuova tag`,
                weekdays: `Giorni della settimana`,
                periods: `Periodi`,
                texts: `Informazioni sull´elemento`,
                lang: `Linguaggio dell'elemento`,
                name: `Nome dell'elemento`,
                description: `Descrizione`,
            },
        },
        categories: {
            title: `Lista di Categoria`,
            new: `Nuova Categoria`,
            not_found: `Categoria non trovata, selezionare un'altra e riprovare!`,
            filters: {
                search: `Ricerca`,
            },
            columns: {
                order: `Ordine`,
                name: `Nome`,
                description: `Descrizione`,
                langs: `Lingue registrate`,
                actions: `Azioni`,
            },
            actions: {
                disable: `Disattivare`,
                enable: `Abilitare`,
                edit: `Modificare`,
                delete: `Eliminar`,
            },
            dialogs: {
                enable: {
                    title: `Abilitare category`,
                    message: `Vuoi davvero abilitare la categoria '{{category_name}}' ?`,
                },
                disable: {
                    title: `Disattivare categoria`,
                    message: `Vuoi davvero disabilitare la categoria '{{category_name}}' ?`,
                },
                delete: {
                    title: `Elimina categoria`,
                    message: `Vuoi veramente eliminare la categoria '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `Nuova Categoria`,
                edit_title: `Modifica Categoria`,
                order: `Ordine`,
                enabled: `Abilitare`,
                texts: `Informazioni sulla categoria`,
                lang: `Lingua della categoria`,
                name: `Nome della categoria`,
                description: `Descrizione`,
            },
        },
        tags: {
            title: `Lista di Tags`,
            new: `Nuova tag`,
            not_found: `Tag non trovato, seleziona un altro e riprova!`,
            filters: {
                search: `Ricerca`,
            },
            columns: {
                order: `Ordine`,
                name: `Nome`,
                description: `Descrizione`,
                langs: `Lingue registrate`,
                actions: `Azioni`,
            },
            actions: {
                disable: `Disattivare`,
                enable: `Abilitare`,
                edit: `Modificare`,
                delete: `Eliminare`,
            },
            dialogs: {
                delete: {
                    title: `Eliminare tag`,
                    message: `Vuoi veramente eliminare il tag '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `Nuova Tag`,
                edit_title: `Modifica Tag`,
                texts: `Informazioni sui tag`,
                lang: `Lingua dei tag`,
                name: `Nome del Tag`,
            },
        },
        users: {
            title: `Lista degli utenti`,
            new: `Nuovo utenti`,
            not_found: `Utenti non trovato, selezionane un altro e riprova!`,
            filters: {
                search: `Ricerca`,
            },
            columns: {
                mail: `Email`,
                username: `Nome degli utenti`,
                fullname: `Nome e cognome`,
                scopes: `Permessi`,
                actions: `Azioni`,
            },
            actions: {
                edit: `Modificare`,
                delete: `Eliminare`,
            },
            dialogs: {
                delete: {
                    title: `Eliminare utenti`,
                    message: `Vuoi veramente cancellare l'utente '{{user_name}}' ?`,
                },
            },
            form: {
                create_title: `Novo Utenti`,
                edit_title: `Modificare Utenti`,
                mail: `E-mail`,
                username: `Nome degli Utenti`,
                fullname: `Nome e cognome`,
                password: `Password`,
                confirm_password: `Conferma password`,
                scopes: `Permessi`,
            },
            profile: {
                title: `Profilo utente`,
                change_password: `Cambia la password`,
            },
        },
        company: {
            title: `Società`,
            time_title: `Informazioni sul fuso orario`,
            periods_title: `Periodo`,
            bookings_title: `Periodo de reservas`,
            runnings_title: `Orario di apertura`,
            booking_title: `Reservas`,
            colors_title: `Colori e stile`,
            disclaimers_title: `Menù Note Legali`,
            request_error: `Impossibile ottenere informazioni sull'azienda.`,
            form: {
                name: `Name`,
                pics: `Items con foto`,
                currency: `Moneta`,
                lang: `Lingua di default`,
                langs: `Lingue disponibili`,
                period: `Periodo`,
                start: `Inizio`,
                end: `Fine`,
                seats: `Posti a sedere`,
                booking_max_seats: `Numero massimo di posti`,
                phone: `Numero di telefono`,
                qrcode_bg: `QR Code colore di sfondo`,
                qrcode_border: `QR Code colore del bordo`,
                booking_approval: `Approva la riserva`,
                booking_interval: `Intervallo tra le riserva`,
                booking_toleration: `Tolleranza di riserva`,
                street_address: `Indirizzo`,
                theme: `Colore del tema`,
                timezone: `Fuso orario dela Azienda`,
                weekday: `Giorno della settimana`,
                law_drive: `Nota bere e guidare`,
                law_tips: `Avviso di mancia`,
                text_tips: `Testo sulla mancia`,
            },
        },
        bookings: {
            title: `Elenco delle prenotazioni`,
            new: `Nuova prenotazione`,
            delete_success: `Prenotazione annullata!`,
            created_success: `Nuova prenotazione creata!`,
            created_fail: `Impossibile creare la prenotazione, riprova.`,
            cancel_fail: `Impossibile annullare la prenotazione, riprova.`,
            final_status: `Lo stato di questa prenotazione non può più essere modificato..`,
            no_date: `Impossibile caricare le informazioni disponibili per la data selezionata, seleziona un'altra data e riprova.`,
            not_found: `Prenotazione non trovata, selezionane un'altra e riprova!`,
            choose_time: `Scegli un orario`,
            how_many: `Tavolo per quante persone?`,
            final_msg_0: `La tua prenotazione sarà sul`,
            final_msg_1: `a`,
            final_msg_2: `ore con una tabella per`,
            final_msg_3: `posti.`,
            filters: {
                search: `Ricerca`,
                status: `Stato della prenotazione`,
            },
            weekdays: {
                sunday: `Dom`,
                monday: `Lun`,
                tuesday: `Mar`,
                wednesday: `Mer`,
                thursday: `Gio`,
                friday: `Ven`,
                saturday: `Sab`,
            },
            customer: {
                menu: `Menu`,
                title: `Prenotazioni`,
                email: `Ti inviamo un'email di conferma, controlla la tua casella di posta.`,
                complete: `Profilo completo`,
                bookings: `Le tue prenotazioni`,
                loading: `Caricamento in corso...`,
                none: `Non hai nessuna prenotazione.`,
            },
            status: {
                all: `Tutti`,
                created: `In attesa di conferma`,
                confirmed: `Confermato`,
                denied: `Negato`,
                canceled: `annullata`,
                used: `Già usato`,
                partial: `In attesa che arrivino i clienti`,
                noshow: `Non ha partecipato`,
            },
            columns: {
                customer_name: `Nome del cliente`,
                customer_email: `E-mail del cliente`,
                datetime: `Data di prenotazione`,
                seats: `Posti a sedere`,
                status: `Status`,
                actions: `Azioni`,
            },
            actions: {
                confirm: `Confermare`,
                deny: `Negare`,
                edit: `Modificare`,
                delete: `Eliminare`,
            },
            dialogs: {
                confirmed: {
                    title: `Conferma prenotazione`,
                    message: `Vuoi confermare la prenotazione per i posti  '{{booking_title}}'?`,
                    success: `Prenotazione confermata.`,
                },
                denied: {
                    title: `Rifiuta prenotazione`,
                    message: `Vuoi negare la prenotazione per i posti '{{booking_title}}' ?`,
                },
                edit: `Modificare la prenotazione`,
                delete: {
                    title: `Elimina prenotazione`,
                    message: `Vuoi davvero eliminare la prenotazione per i posti '{{booking_title}}'?`,
                },
                fail: {
                    title: `Ops!`,
                    message: `Qualcosa è andato storto durante il tentativo di confermare la prenotazione, riprova più tardi!`,
                },
            },
            concierge: {
                hello: `Salve signor concierge!`,
                usereader: `Usa il lettore qui sotto per leggere il codice di prenotazione.`,
                openreader: `Open reader`,
                contact: `Qualche domanda? Contatta il tuo manager o amministratore.`,
                scancode: `Scansione codice prenotazione`,
                closereader: `Chiudi lettore`,
                loading: `Caricamento dati prenotazione...`,
                scanned: `Prenotazione scansionata`,
                name: `Nome`,
                phone: `Telefono`,
                mail: `E-mail`,
                seatsfor: `Posti per`,
                state: `Stato`,
                confirmed: `Persone confermate`,
                remain: `Resti`,
                cod: `Codice`,
                booked: `Prenotato il`,
                back: `Ritornar`,
                confirm: `Conferma prenotazione`,
                is_invalid: `Questa data di prenotazione è al di fuori del periodo di utilizzo.`,
                not_found: `Prenotazione non trovata`,
                recomendation: `Leggi un altro QRCODE e riprova!`,
                last_scan_id: `id: {{booking_id}}`,
            },
            form: {
                customer: `Cliente`,
                datetime: `Data e ora`,
                seats: `Posti a sedere`,
                status: `Status`,
            },
        },
        discounts: {
            title: 'Sconti',
            new: 'Nuovo sconto',
            columns: {
                name: 'Nome',
                type: 'Tipo',
                percent: 'Percentuale %',
                period: 'Periodo',
                items: 'Articoli',
                actions: 'Azioni',
            },
            edit_title: 'Modifica sconto',
            create_title: 'Nuovo sconto',
            name: 'Nome',
            enabled: 'Abilitato',
            percent: 'Percentuale %',
            period: 'Periodo',
            type: 'Tipo',
            items: 'Articoli (Alimenti / Categorie)',
            types: {
                category: 'Categoria',
                food: 'Alimenti',
            },
        },
    },
};
