import React from 'react';
import styled from 'styled-components';

import {useTranslation} from 'react-i18next';

import Loader from './../Loader';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: var(--primary-color);
    color: white;
`;

const Footer = styled.p`
    position: fixed;
    left: 0px;
    bottom: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #f3f3f3;
    font-size: 80%;
`;

function LoadingPage() {
    const {t} = useTranslation();

    return (
        <Container className="loading-container">
            <h2 className="loading-label">{t('loading')}</h2>
            <br />
            <Loader color="#444" />
            <Footer className="loading-footer">{t('footer')}</Footer>
        </Container>
    );
}

export default LoadingPage;
