import axios from 'axios';

export const baseUrl =
    `${window.location.origin.indexOf('localhost') < 0
        ? window.location.origin
        : 'https://dev.alexwb.com.br'
    }/v1`;

const api = axios.create({
    baseURL: baseUrl,
});

api.interceptors.request.use((config) => {
    let token = localStorage.getItem('customer_info') || {};

    try {
        token = JSON.parse(token);
    } catch (error) {
        token = {};
    }

    if (token.userToken) {
        config.headers = {
            ...config.headers,
            Authorization: 'Bearer ' + token.userToken,
            'content-type': 'application/json',
        };
    }

    return config;
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        /*
        if (error.response.status === 401) {
            localStorage.removeItem('customer_info');
            window.location.href('/reservas/login');
        }
        */
        return Promise.reject(error);
    },
);

export default api;
