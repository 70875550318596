import React from 'react';
import styled from 'styled-components';

import { breakpoints } from './../../styles';

import Tag from './../Tag';

const Col = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0px;

    :nth-child(1) {
        flex: 1;
    }
    :nth-child(2) {
        padding-left: 1rem;
        flex: 0.25;
        align-items: flex-end;
    }
`;

const ImageContainer = styled(Col)`
    flex-basis: 20vw !important;
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    width: 30vw;
    height: 20vw;
    max-width: 253px;
    max-height: 172px !important;

    ${({ onClick }) => onClick && 'cursor: pointer;'}

    @media (max-width: ${breakpoints.xs}px) {
        width: 25vw;
        height: 16.5vw;
    }

    ${({ vertical }) =>
        vertical &&
        `
        border-radius: 0.25rem 0.25rem 0 0;
        padding: 0;
        border: 0;
        width: 100% !important;
        margin-bottom: 0.5rem;
        max-height: 155px !important;
    `}
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.085);
    }
    & > div:not(:last-child) {
        margin-right: 1rem;
    }
    :not(:last-child) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }
    ${({ vertical }) =>
        vertical &&
        `
        border-radius: 0.25rem;
        border: 1px solid #ddd;
        padding: 0px !important;
        height: 360px;
        background-color: #fff;
        padding-bottom: 1rem !important;
        width: 200px;
        *:not(${ImageContainer}) {
            pointer-events: none;
        }
    `}
`;

const ItemImage = styled.div`
    background-image: url(${({ src }) => src});
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`;

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    :first-of-type {
        flex-wrap: nowrap;
    }

    ${({ vertical }) =>
        vertical &&
        `
        padding: 0 1rem;
        margin: 0 !important;
    `}
`;

const Title = styled.p`
    font-weight: bold;
    margin-right: auto;
`;

const Normal = styled.span`
    font-weight: normal;
`;

const Description = styled.p`
    white-space: pre-line;

    ${({ vertical }) =>
        vertical &&
        `
        max-height: 70px;
        overflow-y: hidden;
        text-overflow: ellipsis
    `}
`;

const PriceColumn = styled.div`
    & > p:first-child {
        margin: 0;
        padding: 0;
    }

    ${({ vertical }) =>
        vertical &&
        `
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        padding: 0px 1rem !important;
        margin-top: auto;
    `}
`;

const PriceTag = styled.p`
    margin-left: auto;
    text-align: right;
    font-weight: bold;
    ${({ promo }) => promo && `color: #36bf36;`}
    ${({ cut }) =>
        cut &&
        `
        text-decoration: line-through;
        color: #a4a4a4;
        font-size: 85%;
    `}
    ${({ vertical }) =>
        vertical &&
        `
        margin: 0px; !important;
        margin-right: 1rem !important;
    `}
`;

const TagsRow = styled(TitleRow)`
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 2rem;
`;

const ModalImage = styled.img`
    height: auto;
    width: auto;
    max-width: calc(85vw - 2rem);
    max-height: calc(85vh - 2rem);
`;

const MenuItem = ({
    name,
    description,
    price,
    image,
    item,
    vertical,
    ...props
}) => {
    const placeholderImage = '/food-placeholder.png';

    const currentLocale = props.companyInfo.lang.replace('_', '-') || 'pt-BR';
    const defaultCurrency = props.companyInfo.currency || 'BRL';

    const handleShowModal = image
        ? () => {
            let bodyElement = document.querySelector('body');

            let top = window.scrollY;

            bodyElement.style.position = 'fixed';
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = '0px';
            bodyElement.style.right = '0px';

            let content = (
                <ModalImage src={image || placeholderImage} alt="Food" />
            );

            props.setShowModal({
                show: true,
                content,
            });
        }
        : null;

    return (
        <Container vertical={vertical} className="item-container">
            {props.companyInfo.pics === true && vertical && (
                <ImageContainer
                    onClick={handleShowModal}
                    className="image-container"
                    vertical={vertical}
                >
                    <ItemImage
                        src={image || placeholderImage}
                        alt="Food image"
                        className={`item-image ${!image && 'placeholder'}`}
                    />
                </ImageContainer>
            )}
            <TitleRow vertical={vertical}>
                <Title className="item-name">
                    {name}
                    <Normal className="item-code">
                        {item.code &&
                            item.code !== ' ' &&
                            item.code.length > 0 &&
                            ' - ' + item.code}
                    </Normal>
                </Title>
                {!vertical &&
                    (item.promo_price && price ? (
                        <PriceColumn>
                            <PriceTag promo className="price-tag promoted">
                                {item.promo_price.toLocaleString(
                                    currentLocale,
                                    {
                                        style: 'currency',
                                        currency: defaultCurrency,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    },
                                )}
                            </PriceTag>
                            <PriceTag cut className="price-tag cutted">
                                {price.toLocaleString(currentLocale, {
                                    style: 'currency',
                                    currency: defaultCurrency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </PriceTag>
                        </PriceColumn>
                    ) : (
                        price && (
                            <PriceTag className="price-tag">
                                {price.toLocaleString(currentLocale, {
                                    style: 'currency',
                                    currency: defaultCurrency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </PriceTag>
                        )
                    ))}
            </TitleRow>

            <TitleRow vertical={vertical}>
                <Col>
                    <Description
                        vertical={vertical}
                        className="item-description"
                    >
                        {description}
                    </Description>

                    {!vertical && (
                        <TagsRow>
                            {props.tags &&
                                props.tags.length > 0 &&
                                item.tags &&
                                item.tags.length > 0 &&
                                item.tags.map((tag) => {
                                    return (
                                        props.tags.find(
                                            (item) => item.tag_id === tag,
                                        ) && (
                                            <Tag key={tag}>
                                                {
                                                    props.tags.find(
                                                        (item) =>
                                                            item.tag_id === tag,
                                                    ).name
                                                }
                                            </Tag>
                                        )
                                    );
                                })}
                            {props.discount && (
                                <Tag key={props.discount}>
                                    {props.discount}
                                </Tag>
                            )}
                        </TagsRow>
                    )}
                </Col>
                {props.companyInfo.pics === true && !vertical && (
                    <Col>
                        <ImageContainer
                            onClick={handleShowModal}
                            className="image-container"
                        >
                            <ItemImage
                                src={image || placeholderImage}
                                alt="Food image"
                                className={`item-image ${!image && 'placeholder'}`}
                            />
                        </ImageContainer>
                    </Col>
                )}
            </TitleRow>

            {vertical &&
                (item.promo_price && price ? (
                    <PriceColumn vertical={vertical}>
                        <PriceTag
                            vertical={vertical}
                            promo
                            className="price-tag promoted"
                        >
                            {item.promo_price.toLocaleString(currentLocale, {
                                style: 'currency',
                                currency: defaultCurrency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </PriceTag>
                        <PriceTag
                            vertical={vertical}
                            cut
                            className="price-tag cutted"
                        >
                            {price.toLocaleString(currentLocale, {
                                style: 'currency',
                                currency: defaultCurrency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </PriceTag>
                    </PriceColumn>
                ) : (
                    price && (
                        <PriceColumn vertical={vertical}>
                            <PriceTag className="price-tag">
                                {price.toLocaleString(currentLocale, {
                                    style: 'currency',
                                    currency: defaultCurrency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </PriceTag>
                        </PriceColumn>
                    )
                ))}
        </Container>
    );
};

export default MenuItem;
