import React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import styled from 'styled-components';

const Container = styled(Carousel)`
    li {
        :first-child {
            margin-left: 1rem;
        }
        :not(:last-child) {
            margin-right: 1rem;
        }
    }
`;

const Slide = styled.div`
    width: 200px;
`;

const Carroussel = (props) => {
    return (
        <Container draggable itemWidth={200}>
            {props.items.map((item, index) => (
                <Slide key={index}>{item}</Slide>
            ))}
        </Container>
    );
};

export default Carroussel;
