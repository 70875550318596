import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
    color: #fff;
    font-size: 14px;
    background: var(--primary-color);
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    min-width: 64px;
    text-align: center;
    font-weight: normal;

    margin-bottom: 0.5rem;
    ${({rounded}) =>
        rounded &&
        `
            padding: 0px;
            width: 34px;
            height: 34px;
            min-width: initial;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
    `}
    margin: ${({margin}) =>
        margin ||
        `
        :not(:last-child) {
            margin-right: 0.5rem;
        }
    `};
`;

const Tag = (props) => {
    return (
        <Container {...props} className="tag-container">
            {props.children}
        </Container>
    );
};

export default Tag;
