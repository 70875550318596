/* eslint-disable max-len */
export const ptBR = {
    module: {
        disabled: `Modulo desabilitado, contate Zmenu Team para habilita - lo.`,
    },
    company_info: {
        open_now: `Aberto agora`,
        closed_now: `Fechado agora`,
        more_info: `Mais informações`,
    },
    dialogs: {
        success: `Sucesso`,
        attention: `Atenção`,
        confirm: `Sim`,
        submit: `Enviar`,
        edit: `Edit`,
        fail: `Ops!`,
        cancel: `Cancelar`,
        confirmation: `Confirmar`,
        gone_wrong: `Algo deu errado, tente novamente!`,
        not_allowed: `Esse usuário não é autorizado a usar esse módulo.`,
        invalid_login: `O login ou a senha digitados sãp inválidos, tente novamente`,
        same_user: `Já existe uma conta registrada com o email { { email } } . Tente fazer login com esta conta, em vez de associar esta conta social.`,
    },
    login: {
        email: `E - mail`,
        username: `Usuário`,
        password: `Senha`,
        submit: `Entrar`,
        forgot_password: `Esqueceu a senha ? `,
        signup: `Cadastre - se`,
        sigin_google: `Login com Google`,
        sigin_facebook: `Login com Facebook`,
    },
    password_scores: {
        very_weak: `Muito Fraca`,
        weak: `Fraca`,
        okay: `Aceitável`,
        good: `Boa`,
        strong: `Muito boa`,
    },
    signup: {
        title: `Cadastre - se`,
        complete: `Complete seu perfil`,
        name: `Nome completo`,
        phone: `Telefone / Celular`,
        password: `Escolha Sua senha`,
        confirm_password: `Confirme a senha escolhida`,
        submit: `Cadastrar`,
        email: `Email de contato`,
    },
    all: `All`,
    logout: `Sair`,
    loading: `Carregando`,
    footer: `feito com amor por ZMenu ©`,
    dashboard: {
        title: `ZMenu Dashboard`,
        clear_filter: `Limpar busca e filtros`,
        highlights: `Destaques`,
        not_found: `Nós não achamos nenhum prato de acordo com a sua busca.`,
        no_dishes: `Não há pratos no menu hoje.`,
        seach_dish: `Pesquise o seu prato`,
        search: `Busca`,
        menu: {
            menu: `Menu`,
            foods: `Itens`,
            categories: `Categorias`,
            tags: `Tags`,
            discounts: `Descontos`,
            users: `Usuários`,
            profile: `Perfil`,
            reader: `Leitor QRCode`,
            company: `Empresa`,
            bookings: `Reservas`,
        },
        foods: {
            title: `Lista de Itens do Cardápio`,
            new: `Novo Item`,
            not_found: `Item não encontrado, por favor selecione outro e tente novamente!`,
            image: {
                maxsize: `Esta imagem excede o tamanho máximo permitido, envie uma imagem com tamanho máximo de 5MB.`,
                error: `Alguma coisa deu errado ao enviar sua imagem, tente novamente.`,
                remove: `Alguma coisa deu errado ao apagar sua imagem, tente novamente.`,
            },
            filters: {
                search: `Busca`,
                weekdays: `Dias da Semana`,
                periods: `Períodos do dia`,
                inclusive: `Inclusive`,
                preview: `Ver cardápio`,
                categories: `Categorias`,
                status: `Item Status`,
                featured: `Destacado`,
                select_one_preview: `Selecione um item para pré-visualizar o cardápio.`,
                select_only_one_preview: `Selecione somente um item para pré-visualizar o cardápio.`,
                select_one_order: `Selecione um item para habilitar a ordenação`,
                select_only_one_order: `Selecione somente um item para habilitar a ordenação`,
            },
            weekdays: {
                all: `Todos`,
                sunday: `Domingo`,
                monday: `Segunda`,
                tuesday: `Terça`,
                wednesday: `Quarta`,
                thursday: `Quinta`,
                friday: `Sexta`,
                saturday: `Sábado`,
            },
            periods: {
                all: `Todos`,
                coffee: `Café`,
                lunch: `Almoço`,
                snack: `Lanche`,
                dine: `Jantar`,
            },
            status: {
                all: `Todos`,
                enabled: `Habilitado`,
                disabled: `Desabilitado`,
            },
            featured: {
                all: `Todos`,
                featured: `Destacado`,
                not_featured: `Não Destacado`,
            },
            columns: {
                name: `Nome`,
                description: `Descrição`,
                langs: `Idiomas Cadastrados`,
                price: `Preço`,
                categories: `Categoria`,
                weekdays: `Dias da Semana`,
                periods: `Periodos`,
                actions: `Ações`,
            },
            actions: {
                disable: `Desabilitar`,
                enable: `Habilitar`,
                price: `Editar Preço`,
                edit: `Editar`,
                delete: `Apagar`,
            },
            dialogs: {
                enable: {
                    title: `Habilitar Item`,
                    message: `Você realmente quer habilitar o item '{{food_name}}' ?`,
                },
                disable: {
                    title: `Desabilitar Item`,
                    message: `Você realmente quer desabilitar o item '{{food_name}}' ?`,
                },
                price: `Editar Preço`,
                delete: {
                    title: `Apagar Item`,
                    message: `Você realmente quer apagar o item '{{food_name}}' ?`,
                },
            },
            form: {
                create_title: `Novo item`,
                edit_title: `Editar item`,
                image: `Imagem do item`,
                change_image: `Alterar imagem`,
                code: `Código`,
                order: `Ordem`,
                enabled: `Habilitar`,
                featured: `Destaque`,
                price: `Preço`,
                promo_price: `Preço promocional`,
                categories: `Categorias`,
                add_category: `Adicionar nova categoria`,
                tags: `Tags`,
                add_tag: `Adicionar nova tag`,
                weekdays: `Dias`,
                periods: `Periodos`,
                texts: `Informações do Item`,
                lang: `Idioma do Item`,
                name: `Nome`,
                description: `Descrição`,
            },
        },
        categories: {
            title: `Lista de Categorias`,
            new: `Nova categoria`,
            not_found: `Categoria não encontrada, por favor tente novamente!`,
            filters: {
                search: `Busca`,
            },
            columns: {
                order: `Ordem`,
                name: `Nome`,
                description: `Descrição`,
                langs: `Idiomas Cadastrados`,
                actions: `Ações`,
            },
            actions: {
                disable: `Desabilitar`,
                enable: `Habilitar`,
                edit: `Editar`,
                delete: `Apagar`,
            },
            dialogs: {
                enable: {
                    title: `Habilitar categoria`,
                    message: `Você quer realmente habilitar a categoria '{{category_name}}' ?`,
                },
                disable: {
                    title: `Desabilitar categoria`,
                    message: `Você quer realmente desabilitar a categoria '{{category_name}}' ?`,
                },
                delete: {
                    title: `Apagar categoria`,
                    message: `Você quer realmente apagar a categoria '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `Nova Categoria`,
                edit_title: `Editar Catetoria`,
                order: `Ordem`,
                enabled: `Habilitar`,
                texts: `Informações da Categoria`,
                lang: `Idiomas da Categoria`,
                name: `Nome da Categoria`,
                description: `Descrição`,
            },
        },
        tags: {
            title: `Lista de Tags`,
            new: `Nova tag`,
            not_found: `Tag não encontrada, por favor tente novamente!`,
            filters: {
                search: `Busca`,
            },
            columns: {
                order: `Ordem`,
                name: `Nome`,
                description: `Descrição`,
                langs: `Idiomas Cadastrados`,
                actions: `Ações`,
            },
            actions: {
                disable: `Desabilitar`,
                enable: `Habilitar`,
                edit: `Editar`,
                delete: `Apagar`,
            },
            dialogs: {
                delete: {
                    title: `Apagar tag`,
                    message: `Você realmente quer apagar a tag  '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `Nova Tag`,
                edit_title: `Editar Tag`,
                texts: `Informações da Tag`,
                lang: `Idioma da Tag`,
                name: `Nome da Tag`,
            },
        },
        users: {
            title: `Lista de usuários`,
            new: `Novo usuários`,
            not_found: `Usuário não encontrado, por favor selecione outro e tente novamente!`,
            filters: {
                search: `Busca`,
            },
            columns: {
                mail: `Email`,
                username: `Nome de usuário`,
                fullname: `Nome Completo`,
                scopes: `Permissões`,
                actions: `Ações`,
            },
            actions: {
                edit: `Editar`,
                delete: `Apagar`,
            },
            dialogs: {
                delete: {
                    title: `Apagar usuário`,
                    message: `Você realmente quer apagar o usuário '{{user_name}}' ?`,
                },
            },
            form: {
                create_title: `Novo Usuário`,
                edit_title: `Editar Usuário`,
                mail: `E-mail`,
                username: `Nome de Usuário`,
                fullname: `Nome Completo`,
                password: `Senha`,
                confirm_password: `Confirmar Senha`,
                scopes: `Permissões`,
            },
            profile: {
                title: `Perfil do utilizador`,
                change_password: `Trocar Senha`,
            },
        },
        company: {
            title: `Empresa`,
            time_title: `Informações de Fuso horário`,
            periods_title: `Horário do Cardápio`,
            bookings_title: `Horário de Reservas`,
            runnings_title: `Horário de Funcionamento`,
            booking_title: `Reservas`,
            colors_title: `Cores e Estilos`,
            disclaimers_title: `Avisos Legais do Menu`,
            request_error: `Não foi possível carregar as informações da empresa`,
            form: {
                name: `Nome`,
                pics: `Itens com Foto`,
                currency: `Moeda`,
                lang: `Idioma Padrão`,
                langs: `Idiomas Disponíveis`,
                period: `Periodo`,
                start: `Horário Inicial`,
                end: `Horário Final`,
                seats: `Assentos`,
                booking_max_seats: `Quantidade máxima de assentos`,
                phone: `Número de Telefone`,
                qrcode_bg: `Cor de Fundo do QR Code`,
                qrcode_border: `Cor da Borda do QR Code`,
                booking_approval: `Aprovar reservas`,
                booking_interval: `Intervalo entre reservas`,
                booking_toleration: `Tolerância da reserva`,
                street_address: `Endereço`,
                theme: `Cor do Tema`,
                timezone: `Fuso Horário da Empresa`,
                weekday: `Dia da semana`,
                law_drive: `Aviso beber e dirigir`,
                law_tips: `Aviso gorjetas`,
                text_tips: `Texto sobre gorjetas`,
            },
        },
        bookings: {
            title: `Lista de Reservas`,
            new: `Nova Reserva`,
            delete_success: `Reserva Cancelada!`,
            created_success: `Nova reserva criada!`,
            created_fail: `Não pudemos criar uma nova reserva, tente novamente.`,
            cancel_fail: `Não pudemos cancelar sua reserva, tente novamente`,
            final_status: `O status desta reserva não pode mais ser alterada.`,
            no_date: `Não foi possível carregar as informações disponíveis para a data selecionada, selecione outra data e tente novamente.`,
            not_found: `Reserva não encontrada, por favor selecione outro e tente novamente`,
            choose_time: `Escolha um horário`,
            how_many: `Mesa para quantas pessoas?`,
            final_msg_0: `Sua reserva será no dia`,
            final_msg_1: `em`,
            final_msg_2: `horas com mesa para`,
            final_msg_3: `cadeiras.`,
            filters: {
                search: `Busca`,
                status: `Status da Reserva`,
            },
            weekdays: {
                sunday: `Dom`,
                monday: `Seg`,
                tuesday: `Ter`,
                wednesday: `Qua`,
                thursday: `Qui`,
                friday: `Sex`,
                saturday: `Sab`,
            },
            customer: {
                menu: `Menu`,
                title: `Reservas`,
                email: `Nós enviamos um email de confirmação, cheque sua caixa de entrada.`,
                complete: `Perfil completo`,
                bookings: `Suas reservas`,
                loading: `Carregando...`,
                none: `Você não tem nenhuma reserva.`,
            },
            status: {
                all: `Todos`,
                created: `Aguardando confirmação`,
                confirmed: `Confirmada`,
                denied: `Negada`,
                canceled: `Cancelada`,
                used: `Já utilizada`,
                partial: `Aguardando chegada de clientes `,
                noshow: `Não compareceu`,
            },
            columns: {
                customer_name: `Nome do Cliente`,
                customer_email: `E - mail do cliente`,
                datetime: `Data da Reserva`,
                seats: `Assentos`,
                status: `Status`,
                actions: `Ações`,
            },
            actions: {
                confirm: `Confirmar`,
                deny: `Negar`,
                edit: `Editar`,
                delete: `Apagar`,
            },
            dialogs: {
                confirmed: {
                    title: `Confirmar reservas`,
                    message: `Deseja confirmar reserva para '{{booking_title}}' assentos ? `,
                    success: `Reserva confirmada!.`,
                },
                denied: {
                    title: `Negar reserva`,
                    message: `Deseja negar reserva para  '{{booking_title}}' assentos ? `,
                },
                edit: `Editar reserva`,
                delete: {
                    title: `Apagar reserva`,
                    message: `Deseja apagar a reserva para  '{{booking_title}}' assentos ? `,
                },
                fail: {
                    title: `Ops!`,
                    message: `Alguma coisa deu errado quando tentamos confirmar esta reserva, tente novamente!!`,
                },
            },
            concierge: {
                hello: `Olá Sr.Concierge!`,
                usereader: `Use o leitor abaixo para ler o código de reserva.`,
                openreader: `Abra o leitor`,
                contact: `Alguma dúvida ? Entre em contato com o gerente ou administrador.`,
                scancode: `Lendo QrCode da Reserva`,
                closereader: `Feche o leitor`,
                loading: `Carregando dados da reserva...`,
                scanned: `Reserva lida`,
                name: `Nome`,
                phone: `Telefone`,
                mail: `E - mail`,
                seatsfor: `Assentos para`,
                state: `Estado`,
                confirmed: `Pessoas confirmadas`,
                remain: `Restam`,
                cod: `Codigo`,
                booked: `Reservado em`,
                back: `Voltar`,
                confirm: `Confirmar reserva`,
                is_invalid: `Essa reserva está fora do seu periodo de uso.`,
                not_found: `Reserva não encontrada`,
                recomendation: `Por favor leia outro QRCODE e tente novamente!`,
                last_scan_id: `id: {{booking_id}}`,
            },
            form: {
                customer: `Cliente`,
                datetime: `Data e hora`,
                seats: `Assentos`,
                status: `Status`,
            },
        },
        discounts: {
            title: 'Descontos',
            new: 'Novo desconto',
            columns: {
                name: 'Nome',
                type: 'Tipo',
                percent: 'Percentual %',
                period: 'Periodo',
                items: 'Items',
                actions: 'Ações',
            },
            edit_title: 'Editar Desconto',
            create_title: 'Novo Desconto',
            name: 'Nome',
            enabled: 'Habilitado',
            percent: 'Percentual %',
            period: 'Periodo',
            type: 'Tipo',
            items: 'Itens (Comidas / Categorias)',
            types: {
                category: 'Categoria',
                food: 'Comida',
            },
        },
    },
};
