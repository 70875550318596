import styled, {createGlobalStyle} from 'styled-components';

export const colors = {};

export const breakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
};

export const GlobalStyle = createGlobalStyle`
    :root {
        --primary-color: #d31021;
    }

    input, button, input:hover, button:hover, input:focus, button:focus {
        outline: 0px;
        box-shadow: none;
    }

    .text-center
    {
        text-align: center;
    }
`;

export const Flex = styled.div`
    position: relative;
    display: flex;
    flex-wrap: ${({wrap}) => (wrap ? wrap : 'wrap')};
    flex-grow: ${({grow}) => (grow ? grow : 'initial')};
    ${({alignSelf}) => alignSelf && `align-self: ${alignSelf};`};
    align-items: ${({alignItems}) => (alignItems ? alignItems : 'center')};
    justify-content: ${({justifyContent}) =>
        justifyContent ? justifyContent : 'center'};
    ${({background}) => background && `background: ${background}`};
    ${({backgroundColor}) =>
        backgroundColor && `background-color: ${backgroundColor}`};
    ${({border}) => border && `border: ${border}`};
    ${({boxShadow}) => boxShadow && `box-shadow: ${boxShadow}`};
    ${({margin}) => margin && `margin: ${margin}`};
    ${({padding}) => padding && `padding: ${padding}`};
    ${({width}) => width && `width: ${width}`};
    ${({height}) => height && `height: ${height}`};
    ${({minWidth}) => minWidth && `min-width: ${minWidth}`};
    ${({maxWidth}) => maxWidth && `max-width: ${maxWidth}`};
    ${({minHeight}) => minHeight && `min-height: ${minHeight}`};
    ${({maxHeight}) => maxHeight && `max-height: ${maxHeight}`};
    ${({position}) => position && `position: ${position}`};
    ${({zIndex}) => zIndex && `z-index: ${zIndex}`};
    ${({boxSizing}) => boxSizing && `box-sizing: ${boxSizing}`};
    ${({flex}) => flex && `flex: ${flex}`};
    ${({direction}) => direction && `flex-direction: ${direction}`};
    ${({order}) => order && `order: ${order}`};
    ${({overflow}) => overflow && `overflow: ${overflow}`};
    ${({transition}) => transition && `transition: ${transition}`};
    ${({onClick}) => onClick && 'cursor: pointer'};

    ${(props) =>
        props.alignLast &&
        `
        &::after {
            content: "";
            flex: auto;
        }
    `}
`;
