import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, render, path, ...props }) => {
    let token = localStorage.getItem('user_token') || {};

    try {
        token = JSON.parse(token);
    } catch (error) {
        token = {};
    }

    const authed =
        token.access_token &&
        token.expires_in &&
        token.expires_in > Date.now() / 1000;

    return (
        <Route
            path={path}
            {...props}
            render={(props) => {
                if (authed) {
                    return render ? render() : <Component {...props} />;
                } else {
                    localStorage.removeItem('user_token');
                    return (
                        <Redirect
                            to={
                                path === '/concierge/*'
                                    ? '/concierge/login'
                                    : '/admin/login'
                            }
                        />
                    );
                }
            }}
        />
    );
};

export default PrivateRoute;
