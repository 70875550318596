import React, {useState} from 'react';
import Flag from 'react-flags';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    background: #f4f4f4b3;
    padding: 0.25rem 0.5rem;
    margin: 1rem;
    min-width: 64px;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 1;
`;

const Row = styled.div`
    display: block;
    flex-direction: column;
`;

const LangLabel = styled.span`
    font-size: 1rem;
    color: #222;
    font-weight: bold;
    margin-left: 0.5rem;
    cursor: pointer;
`;

const LanguageSelector = ({locale, setLocale, locales, ...props}) => {
    const [open, setOpen] = useState(false);

    return (
        <Container
            onClick={() => setOpen(!open)}
            onMouseLeave={() => setTimeout(() => setOpen(false), 250)}
        >
            <Row>
                <Flag
                    name={locale ? locale.split('_')[1] : 'BR'}
                    format="png"
                    pngSize={32}
                    shiny={true}
                    basePath={'/images'}
                />
                <LangLabel>
                    {locale ? locale.toUpperCase().split('_')[1] : 'BR'}
                </LangLabel>
            </Row>
            {open &&
                locales.map((item) => {
                    if (
                        locale
                            ? locale.split('_')[1] !== item.split('_')[1]
                            : item.split('_')[1] !== 'BR'
                    ) {
                        return (
                            <Row key={item} onClick={() => setLocale(item)}>
                                <Flag
                                    name={item.split('_')[1]}
                                    format="png"
                                    pngSize={32}
                                    shiny={true}
                                    basePath={'/images'}
                                />
                                <LangLabel>
                                    {item.toUpperCase().split('_')[1]}
                                </LangLabel>
                            </Row>
                        );
                    } else {
                        return null;
                    }
                })}
        </Container>
    );
};

export default LanguageSelector;
