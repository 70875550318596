/* eslint-disable max-len */
export const esES = {
    module: {
        disabled: `Module disabled, contact Zmenu Team to enable it.`,
    },
    company_info: {
        open_now: `Aberto agora`,
        closed_now: `Fechado agora`,
        more_info: `Mais informações`,
    },
    dialogs: {
        attention: `Atención!`,
        success: `Success!`,
        confirm: `Si`,
        submit: `Guardar`,
        edit: `Edit`,
        fail: `Ops!`,
        cancel: `Cancelar`,
        confirmation: `Confirmación`,
        gone_wrong: `Algo salió mal.Por favor, vuelva a intentar!`,
        not_allowed: `Este usuario no está autorizado a utilizar este module.`,
        invalid_login: `Nombre de usuario o contraseña no válido, compruébalo e inténtalo de nuevo.`,
        same_user: `Una cuenta con el correo electrónico { { email } } ya existe.Intente iniciar sesión con esta cuenta, en lugar de asociar esta cuenta social.`,
    },
    login: {
        email: `Email`,
        username: `Nombre de usuario`,
        password: `Contrasenã`,
        submit: `Enviar`,
        forgot_password: `¿olvido la contraseña ? `,
        signup: `Registrarse`,
        sigin_google: `Inicia con Google`,
        sigin_facebook: `Inicia con Facebook`,
    },
    password_scores: {
        very_weak: `Muy débil`,
        weak: `Débil`,
        okay: `Aceptable`,
        good: `Buena`,
        strong: `Fuerte`,
    },
    signup: {
        title: `Registrarse`,
        complete: `Completa tu perfil`,
        name: `Nombre completo`,
        phone: `Teléfono / Celular`,
        password: `Elige tu contraseña`,
        confirm_password: `Confirma la contraseña elegida`,
        submit: `Registrar`,
        email: `Email de contacto`,
    },
    all: `All`,
    logout: `Cerrar Sesión`,
    loading: `Cargando`,
    footer: `hecho com Amor por ZMenu ©`,
    dashboard: {
        title: `ZMenu Dashboard`,
        clear_filter: `Borrar búsqueda y filtros`,
        highlights: `Destaques`,
        not_found: `No encontramos ningún plato según tu búsqueda.`,
        no_dishes: `Hoy no hay platos en el menú.`,
        seach_dish: `Busca tu plato`,
        search: `Búsqueda`,
        menu: {
            menu: `Menu`,
            foods: `Elementos`,
            categories: `Categorias`,
            tags: `Tags`,
            discounts: `Descontos`,
            users: `Usuarios`,
            profile: `Perfil`,
            reader: `Lector de QrCode`,
            company: `Empresa`,
            bookings: `Reservas`,
        },
        foods: {
            title: `Elemento del menú`,
            new: `Nuevo Elemento`,
            not_found: `No se encontró el artículo, seleccione otro y vuelva a intentarlo!`,
            image: {
                maxsize: `Superado el tamaño máximo de imagen, cargue una nueva imagen de máximo 5MB.`,
                error: `Se produjo un error al cargar la imagen, inténtelo de nuevo.`,
                remove: `Se produjo un error con la eliminación de la imagen. Vuelve a intentarlo.`,
            },
            filters: {
                search: `Búsqueda`,
                weekdays: `Dias de la semana`,
                periods: `Periodos`,
                inclusive: `Inclusive`,
                preview: `Ver Menú`,
                categories: `Categorias`,
                status: `Status del elemento`,
                featured: `Destacado`,
                select_one_preview: `Seleccione uno para habilitar la vista previa del menú`,
                select_only_one_preview: `Seleccione solo uno para habilitar la vista previa del menú`,
                select_one_order: `Seleccione uno para habilitar ordenar`,
                select_only_one_order: `Seleccione solo uno para habilitar ordenar`,
            },
            weekdays: {
                all: `Todos`,
                sunday: `Domingo`,
                monday: `Lunes`,
                tuesday: `Martes`,
                wednesday: `Miércoles`,
                thursday: `Jueves`,
                friday: `Viernes`,
                saturday: `Sábado`,
            },
            periods: {
                all: `Todos`,
                coffee: `desayuno`,
                lunch: `almuerzo`,
                snack: `merienda`,
                dine: `cenar`,
            },
            status: {
                all: `Todos`,
                enabled: `Activado`,
                disabled: `Desactivado`,
            },
            featured: {
                all: `Todos`,
                featured: `Destacado`,
                not_featured: `No Destacado`,
            },
            columns: {
                name: `Nombre`,
                description: `Descripción`,
                langs: `Idiomas registrados`,
                price: `Precio`,
                categories: `Categorias`,
                weekdays: `Dias de la semana`,
                periods: `Periodos`,
                actions: `Acciones`,
            },
            actions: {
                disable: `Desactivar`,
                enable: `Activar`,
                price: `Editar Precio`,
                edit: `Editar`,
                delete: `Borrar`,
            },
            dialogs: {
                enable: {
                    title: `Activar Item`,
                    message: `¿Realmente quieres activar el elemento '{{food_name}}' ?`,
                },
                disable: {
                    title: `Desactivar item`,
                    message: `¿Realmente quieres desactivar el elemento '{{food_name}}' ?`,
                },
                price: `Editar precio`,
                delete: {
                    title: `Borrar item`,
                    message: `¿Realmente quieres borrar el elemento '{{food_name}}' ?`,
                },
            },
            form: {
                create_title: `Nuevo Elemento`,
                edit_title: `Editar Elemento`,
                image: `Imagen del elemento`,
                change_image: `Cambiar imagen`,
                code: `Código`,
                order: `Orden`,
                enabled: `Activado`,
                featured: `Destacado`,
                price: `Precio`,
                promo_price: `Precio promocional`,
                categories: `Categorias`,
                add_category: `Añadir nueva categoria`,
                tags: `Tags`,
                add_tag: `Añadir nueva tag`,
                weekdays: `Dias de la semana`,
                periods: `Periodos`,
                texts: `Información del elemento`,
                lang: `Idioma del elemento`,
                name: `Nombre del elemento`,
                description: `Descripción`,
            },
        },
        categories: {
            title: `Lista de categorías`,
            new: `Nueva Categoria`,
            not_found: `Categoría no encontrada, seleccione otra y vuelva a intentarlo.`,
            filters: {
                search: `Buscar`,
            },
            columns: {
                order: `Ordem`,
                name: `Nombre`,
                description: `Descripción`,
                langs: `Idiomas registrados`,
                actions: `Acciones`,
            },
            actions: {
                disable: `Desactivar`,
                enable: `Activar`,
                edit: `Editar`,
                delete: `Borrar`,
            },
            dialogs: {
                enable: {
                    title: `Activar categoria`,
                    message: `¿Realmente quieres activar la categoria '{{category_name}}' ?`,
                },
                disable: {
                    title: `Desactivar categoria`,
                    message: `¿Realmente quieres desactivar la categoria '{{category_name}}' ?`,
                },
                delete: {
                    title: `Borrar categoria`,
                    message: `¿Realmente quieres borrar la categoria '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `Nueva categoria`,
                edit_title: `Editar Categoria`,
                order: `Orden`,
                enabled: `Active`,
                texts: `Informacion de la categoria`,
                lang: `Idioma de la categoria`,
                name: `Nombre de la Categoria`,
                description: `Descripción`,
            },
        },
        tags: {
            title: `Lista de Tags`,
            new: `Nueva tag`,
            not_found: `Tag no encontrada, seleccione otra e intente nuevamente.`,
            filters: {
                search: `Buscar`,
            },
            columns: {
                order: `Orden`,
                name: `Nombre`,
                description: `Descripción`,
                langs: `Idiomas registrados`,
                actions: `Acciones`,
            },
            actions: {
                disable: `Activar`,
                enable: `Desactivar`,
                edit: `Editar`,
                delete: `Borrar`,
            },
            dialogs: {
                delete: {
                    title: `Borrar tag`,
                    message: `¿Realmente quieres borrar la tag '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `Nueva Tag`,
                edit_title: `Editar Tag`,
                texts: `Información de la Tag`,
                lang: `Idioma de la Tag`,
                name: `Nombre de la Tag`,
            },
        },
        users: {
            title: `Lista de usuarios`,
            new: `Novo usuario`,
            not_found: `Usuario no encontrado, seleccione otro e intente nuevamente!`,
            filters: {
                search: `Buscar`,
            },
            columns: {
                mail: `Email`,
                username: `Nombre del usuario`,
                fullname: `Nombre Completo`,
                scopes: `Permisos`,
                actions: `Acciones`,
            },
            actions: {
                edit: `Editar`,
                delete: `Borrar`,
            },
            dialogs: {
                delete: {
                    title: `Borrar usuario`,
                    message: `¿Realmente quieres borrar al usuario '{{user_name}}' ?`,
                },
            },
            form: {
                create_title: `Nuevo Usuario`,
                edit_title: `Editar Usuario`,
                mail: `E-mail`,
                username: `Nombre del Usuario`,
                fullname: `Nombre Completo`,
                password: `Senha`,
                confirm_password: `Confirmar contraseña`,
                scopes: `Permisos`,
            },
            profile: {
                title: `Perfil del usuario`,
                change_password: `Cambiar contraseña`,
            },
        },
        company: {
            title: `Empresa`,
            time_title: `Información de zona horaria`,
            periods_title: `Periodos`,
            bookings_title: `Periodos de reservas`,
            runnings_title: `horas de oficina`,
            booking_title: `Reservas`,
            colors_title: `Colores y estilo`,
            disclaimers_title: `Menú Avisos Legales`,
            request_error: `No se pudo obtener la información de la empresa`,
            form: {
                name: `Nombre`,
                pics: `Item con fotos`,
                currency: `Moneda`,
                lang: `Idioma padrão`,
                langs: `Idiomas disponibles`,
                period: `Periodo`,
                start: `Comienzo`,
                end: `Final`,
                seats: `Asientos`,
                booking_max_seats: `Cantidad máxima de asientos`,
                phone: `Número de teléfonor`,
                qrcode_bg: `QR Code - Color de fondo`,
                qrcode_border: `QR Code - color del borde`,
                booking_approval: `Aprovar reservas`,
                booking_interval: `Intervalo entre reservas`,
                booking_toleration: `Tolerancia de reserva`,
                street_address: `Dirección`,
                theme: `Color del tema`,
                timezone: `Zona horaria de la Empresa `,
                weekday: `Dia de la semana`,
                law_drive: `Advertencia beber y conducir`,
                law_tips: `Aviso de consejos`,
                text_tips: `Texto sobre consejos`,
            },
        },
        bookings: {
            title: `Lista de Reservas`,
            new: `Nueva reserva`,
            delete_success: `Reserva Cancelada!`,
            created_success: `Nueva reserva creada!`,
            created_fail: `No se pudo crear su reserva. Vuelva a intentarlo.`,
            cancel_fail: `No se pudo cancelar la reserva, inténtelo de nuevo.`,
            final_status: `El estado de esta reserva ya no se puede cambiar.`,
            no_date: `No se pudo cargar la información disponible para la fecha seleccionada, seleccione otra fecha y vuelva a intentarlo.`,
            not_found: `No se encontró la reserva, seleccione otra y vuelva a intentarlo!`,
            choose_time: `Elige una hora`,
            how_many: `Tabla para cuantos personas?`,
            final_msg_0: `Tu reserva estará en el`,
            final_msg_1: `en`,
            final_msg_2: `horas con una tabla para`,
            final_msg_3: `asientos.`,
            filters: {
                search: `Búsqueda`,
                status: `Estado de la reservacións`,
            },
            weekdays: {
                sunday: `Dom`,
                monday: `Lun`,
                tuesday: `Mar`,
                wednesday: `Mie`,
                thursday: `Jue`,
                friday: `Vie`,
                saturday: `Sab`,
            },
            customer: {
                menu: `Menu`,
                title: `Reservas`,
                email: `Te enviamos un correo electrónico de confirmación, revisa tu bandeja de entrada.`,
                complete: `Perfil completo`,
                bookings: `Tus reservas`,
                loading: `Cargando...`,
                none: `No tienes ninguna reserva.`,
            },
            status: {
                all: `Todos`,
                created: `Esperando confirmacion`,
                confirmed: `Confirmada`,
                denied: `Negada`,
                canceled: `Cancelada`,
                used: `Ya usedo`,
                partial: `Esperando a que lleguen los clientes`,
                noshow: `No asistio`,
            },
            columns: {
                customer_name: `Nombre del cliente`,
                customer_email: `E - mail del cliente`,
                datetime: `Fecha para registrarse`,
                seats: `Asientos`,
                status: `Status`,
                actions: `Acciones`,
            },
            actions: {
                confirm: `Confirmar`,
                deny: `Denegar`,
                edit: `Editar`,
                delete: `Borrar`,
            },
            dialogs: {
                confirmed: {
                    title: `Confirmar reserva`,
                    message: `¿Quieres confirmar la reserva de los asientos de  '{{booking_title}}' ? `,
                    success: `Reserva confirmada.`,
                },
                denied: {
                    title: `Denegar reserva`,
                    message: `¿Quieres denegar la reserva de asientos de '{{booking_title}}' ? `,
                },
                edit: `Editar Reserva`,
                delete: {
                    title: `Eliminar reserva`,
                    message: `Realmente desea eliminar la reserva de asientos '{{booking_title}}' ? `,
                },
                fail: {
                    title: `Ops!`,
                    message: `Se produjo un error al intentar confirmar la reserva.Vuelve a intentarlo más tarde.!`,
                },
            },
            concierge: {
                hello: `Hola señor conserje!`,
                usereader: `Utilice el lector a continuación para ler el código de reserva.`,
                openreader: `Abre tu lector`,
                contact: `¿Alguna pregunta ? Comuníquese con su gerente o administrador.`,
                scancode: `Escanear código de reserva`,
                closereader: `Lector cercano`,
                loading: `Cargando datos de reserva...`,
                scanned: `Reserva escaneada`,
                name: `Nombre`,
                phone: `Teléfono`,
                mail: `E - mail`,
                seatsfor: `Asientos para`,
                state: `Estado`,
                confirmed: `Personas confirmadas`,
                remain: `Para ir`,
                cod: `Codigo`,
                booked: `Reservado el`,
                back: `Volver`,
                confirm: `Confirmar la reserva`,
                is_invalid: `Esta reserva está fuera del período de uso.`,
                not_found: `Reserva no encontrada`,
                recomendation: `Por favor lea otro QRCODE y vuelva a intentarlo!`,
                last_scan_id: `id: {{booking_id}}`,
            },
            form: {
                customer: `Cliente`,
                datetime: `Fecha y hora`,
                seats: `Assientos`,
                status: `Status`,
            },
        },
        discounts: {
            title: 'Descuentos',
            new: 'Nuevo Descuento',
            columns: {
                name: 'Nombre',
                type: 'Tipo',
                percent: 'Porcentaje %',
                period: 'Periodo',
                items: 'Artículos',
                actions: 'Acciones',
            },
            edit_title: 'Editar Descuento',
            create_title: 'Novo Desconto',
            name: 'Nombre',
            enabled: 'Habilitado',
            percent: 'Porcentaje %',
            period: 'Periodo',
            type: 'Tipo',
            items: 'Artículos (Alimentos / Categorias)',
            types: {
                category: 'Categoria',
                food: 'Alimentos',
            },
        },
    },
};
