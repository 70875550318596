import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {resources} from './translations/resources';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng:
            window.location.pathname.indexOf('menu') > 0 && localStorage.locale
                ? localStorage.locale.split('_')[0]
                : undefined,
        fallbackLng: 'en',
        debug: false,
        resources,
    });

export default i18n;
