import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import api from './../../services/api';
import { breakpoints } from './../../styles';

import Header from './../../Components/MenuHeader';
import MenuItem from './../../Components/MenuItem';
import LoadingPage from './../../Components/LoadingPage';
import LanguageSelector from '../../Components/LanguageSelector';
import Footer from '../../Components/Footer';
import Modal from '../../Components/Modal';
import Carroussel from '../../Components/Carroussel';
import { useTranslation } from 'react-i18next';


import Button from '@material-ui/core/Button';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const ItemsRow = styled.div`
    width: calc(100% - 2rem);
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #f8f9fa;
    margin: 1rem auto 0 auto;
    padding: ${({ padding }) => (padding ? padding : '1rem')};
    min-height: ${({ minHeight }) =>
        minHeight ? minHeight : 'calc(100vh - 352px - 82px)'};
    ${({ height }) => (height ? `height: ${height};` : '')};
    @media (max-width: ${breakpoints.md}px) {
        width: 100%;
        margin: 1rem 0 0 0;
    }
    ${({ padding }) =>
        padding === '1rem 0' &&
        `
        & > h2 {
            margin-left: 1rem;
        }
    `};
`;

const CategoryDescription = styled.p`
    color: #444;
    font-size: 1rem;
    white-space: pre-line;
`;

const PreviewContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    min-height: 32px;
    background-color: #808080;
    color: white;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 2;

    & > button {
        margin-right: auto;
        color: white;
    }

    & > h4 {
        padding: 0px;
        margin-right: auto;
        margin-bottom: 0px;
    }
`;

const MenuPage = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const currentUrl = new URL(window.location.href);

    let currentWeekday = currentUrl.searchParams.get('weekday');
    let currentPeriods = (currentUrl.searchParams.get('periods') || '').split(',');

    const previewing = currentWeekday && currentPeriods ? true : false;

    const [pageStatus, setPageStatus] = useState('loading');

    const [langs, setLangs] = useState(undefined);
    const [tags, setTags] = useState(undefined);
    const [categories, setCategories] = useState(undefined);
    const [items, setItems] = useState(undefined);
    const [companyInfo, setCompanyInfo] = useState(undefined);

    const [locale, setLocale] = useState(
        localStorage.getItem('locale') !== 'null'
            ? localStorage.getItem('locale')
            : undefined,
    );

    const [searchValue, setSearchValue] = useState('');

    const [showModal, setShowModal] = useState({
        show: false,
        content: undefined,
    });

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (tags && categories && items && companyInfo && langs) {
            setTimeout(() => {
                setPageStatus('ready');
            }, 500);
        } else {
            setPageStatus('loading');
        }
    }, [categories, companyInfo, items, langs, tags]);

    useEffect(() => {
        setPageStatus('loading');

        setTags(undefined);
        setCategories(undefined);
        setItems(undefined);
        setCompanyInfo(undefined);
        setLangs(undefined);

        loadTodayMenu();

        if (locale !== undefined && locale !== null) {
            localStorage.setItem('locale', locale);
        }

        // eslint-disable-next-line
    }, [locale]);

    const loadTodayMenu = async () => {
        let url = `menu/today?${locale && 'locale=' + locale}`;
        let data = {};

        if (currentWeekday) {
            url = `menu/${currentWeekday}?${locale && 'locale=' + locale}`;
            data = currentPeriods;
        }

        let response = await api({
            method: currentWeekday ? 'post' : 'get',
            url,
            data,
        })
            .then((res) => res)
            .catch((err) => err);

        if (response.status === 200) {
            let newCategories = {};

            response.data.categories &&
                response.data.categories.forEach((category) => {
                    newCategories[category.category_id] = category;
                });

            response.data.foods.forEach((food) => {
                food.categories.forEach((category) => {
                    if (newCategories[category]) {
                        newCategories[category].foods = newCategories[category]
                            .foods
                            ? [...newCategories[category].foods, food]
                            : [food];
                    }
                });
            });

            setTags(response.data.tags.length === 0 ? [] : response.data.tags);
            setCategories(newCategories.length === 0 ? [] : newCategories);
            setItems(
                response.data.foods.length === 0 ? [] : response.data.foods,
            );
            setCompanyInfo(response.data.company);
            setLangs(
                response.data.company.langs.length === 0
                    ? []
                    : response.data.company.langs,
            );
        } else {
            setTags([]);
            setCategories([]);
            setItems([]);
            setCompanyInfo({});
            setLangs([]);
        }
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    };

    const handleSearchFilter = (food) => {
        if (
            searchValue === null ||
            searchValue === undefined ||
            searchValue.trim().length <= 0
        ) {
            return true;
        }

        let search = searchValue.toLowerCase();

        let hasTags = false;

        if (food.tags && tags) {
            hasTags =
                food.tags.filter((filter_tag) => {
                    return tags.find(
                        (find_tag) => find_tag.tag_id === filter_tag,
                    )
                        ? tags
                            .find(
                                (find_tag) => find_tag.tag_id === filter_tag,
                            )
                            .name.toLowerCase()
                            .indexOf(search) >= 0
                        : false;
                }).length > 0;
        }

        return (
            (food.name && food.name.toLowerCase().indexOf(search) >= 0) ||
            (food.description &&
                food.description.toLowerCase().indexOf(search) >= 0) ||
            hasTags
        );
    };

    let filteredCategories = categories && [
        ...Object.values(categories).filter(
            (item) => item.foods && item.foods.length > 0,
        ),
    ];

    if (searchValue.trim().length > 0) {
        filteredCategories = filteredCategories.filter((item) => {
            return item.foods.filter(handleSearchFilter).length > 0;
        });
    }

    return (
        <PageContainer>
            {companyInfo && (
                <Helmet>
                    {companyInfo.name && (
                        <title>ZMenu - {companyInfo.name}</title>
                    )}

                    {companyInfo.theme && (
                        <meta name="theme-color" content={companyInfo.theme} />
                    )}
                    {companyInfo.theme && (
                        <meta
                            name="apple-mobile-web-app-status-bar-style"
                            content={companyInfo.theme}
                        />
                    )}
                </Helmet>
            )}

            {pageStatus === 'loading' || pageStatus !== 'ready' ? (
                <LoadingPage />
            ) : (
                <>
                    {previewing && (
                        <PreviewContainer className="preview-container">
                            <Button
                                color="textPrimary"
                                variant="text"
                                onClick={() => history.goBack()}
                                className="preview-button"
                            >
                                <ChevronLeftIcon /> Back
                            </Button>
                            <h4 className="preview-text">
                                Previewing {` ${currentWeekday}'s `}
                                {` ${currentPeriods
                                    .join(', ')
                                    .replace(/, ([^, ]*)$/, ' and $1')} `}{' '}
                                Menu
                            </h4>
                        </PreviewContainer>
                    )}

                    {langs.length > 1 && (
                        <LanguageSelector
                            locale={locale}
                            locales={langs}
                            setLocale={setLocale}
                        />
                    )}
                    <Header
                        categories={filteredCategories}
                        companyName={companyInfo.name}
                        companyInfo={companyInfo}
                        handleSearch={handleSearch}
                        searchValue={searchValue}
                        setShowModal={setShowModal}
                    />

                    {showModal && showModal.show && showModal.content && (
                        <Modal setShowModal={setShowModal}>
                            {showModal.content}
                        </Modal>
                    )}

                    {items.filter((item) => item.featured === true).length > 0 && (
                        <ItemsRow
                            padding="1rem 0"
                            className="category-container"
                        >
                            <h2 className="category-title featured">
                                {t('dashboard.highlights')}
                            </h2>

                            <Carroussel
                                items={[
                                    ...items
                                        .filter(
                                            (item) => item.featured === true,
                                        )
                                        .sort((a, b) =>
                                            (a.order || 0) > (b.order || 0)
                                                ? 1
                                                : -1,
                                        )
                                        .map((food) => (
                                            <MenuItem
                                                key={food.food_id}
                                                image={food.image}
                                                name={food.name}
                                                description={food.description}
                                                price={food.price}
                                                item={food}
                                                tags={tags}
                                                setShowModal={setShowModal}
                                                companyInfo={companyInfo}
                                                vertical
                                            />
                                        )),
                                ]}
                            />
                        </ItemsRow>
                    )}

                    {filteredCategories.length === 0 ? (
                        <ItemsRow key={0} className="no-items">
                            <h2 className="category-title text-center">
                                {searchValue.trim().length > 0
                                    ? t('dashboard.not_found')
                                    : t('dashboard.no_dishes')
                                }
                            </h2>
                        </ItemsRow>
                    ) : (
                        filteredCategories.map((category) => (
                            <ItemsRow
                                key={category.category_id}
                                className="category-container"
                            >
                                <h2
                                    data-id={category.category_id}
                                    className="category-title"
                                >
                                    {category.name}
                                </h2>
                                <CategoryDescription className="category-description">
                                    {category.description}
                                </CategoryDescription>
                                {category.foods &&
                                    category.foods
                                        .filter(handleSearchFilter)
                                        .sort((a, b) =>
                                            (a.order || 0) > (b.order || 0)
                                                ? 1
                                                : -1,
                                        )
                                        .map((food) => (
                                            <MenuItem
                                                key={food.food_id}
                                                image={food.image}
                                                name={food.name}
                                                description={food.description}
                                                price={food.price}
                                                discount={food.discount_name}
                                                item={food}
                                                tags={tags}
                                                setShowModal={setShowModal}
                                                companyInfo={companyInfo}
                                            />
                                        ))}
                            </ItemsRow>
                        ))
                    )}
                    <Footer notify_drink={true} disclaimers={companyInfo.disclaimers} />
                </>
            )}
        </PageContainer>
    );
};

export default MenuPage;
