import React, { useState, Suspense, useEffect } from 'react';
import styled from 'styled-components';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FirebaseAuthProvider, FirebaseAuthConsumer } from '@react-firebase/auth';

import { GlobalStyle } from './styles';
import { history } from './helpers/history';

import api from './services/api/customer';

import MenuPage from './Pages/MenuPage';

import PrivateRoute from './Components/PrivateRoute';

import LoadingPage from './Components/LoadingPage';

import './styles/_reboot.css';
import { useTranslation } from 'react-i18next';

const LoginPage = React.lazy(() => import('./Pages/LoginPage'));
const DashboardPage = React.lazy(() => import('./Pages/DashboardPage'));
const GlobalSnackbar = React.lazy(() => import('./Components/GlobalSnackbar'));
const GlobalDialog = React.lazy(() => import('./Components/GlobalDialog'));
const CustomerLoginPage = React.lazy(() => import('./Pages/CustomerLoginPage'));
const CustomerCompleteProfile = React.lazy(() => import('./Pages/CustomerCompleteProfile'));
const CustomerBookingPage = React.lazy(() => import('./Pages/CustomerBookingPage'));
const ConciergePage = React.lazy(() => import('./Pages/ConciergePage'));

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
`;

const App = (props) => {
    const { t } = useTranslation();

    const firebaseConfig = {
        apiKey: 'AIzaSyBRYX0L47Rfn35WLw9xd_dGfBYqly9isvk',
        authDomain: 'zmenu-reservas.firebaseapp.com',
        projectId: 'zmenu-reservas',
        storageBucket: 'zmenu-reservas.appspot.com',
        messagingSenderId: '658982559926',
        appId: '1:658982559926:web:97355165d46a2e2b02c617',
        measurementId: 'G-CZBW3EH7YC',
    };

    const [customerToken, setCustomerToken] = useState(null);
    const [customerProfile, setCustomerProfile] = useState(null);

    useEffect(() => {
        customerToken && loadUserProfile();

        //eslint-disable-next-line
    }, [customerToken]);

    const loadUserProfile = async () => {
        console.log("App");
        let response = await api
            .get('/booking/profile', {
                headers: {
                    Authorization: `Bearer ${customerToken}`,
                }
            })
            .then((res) => res)
            .catch((err) => err.response);

        if (response?.status === 200) {
            setCustomerProfile(response.data);

            localStorage.setItem(
                'customer_info',
                JSON.stringify({
                    userToken: customerToken,
                    ...response.data,
                }),
            );
        } else {
            window.dialog(
                t('dialogs.attention'),
                response
                    ? response.data && response.data.message
                        ? response.data.message
                        : response.status === 401
                            ? t('dialogs.invalid_login')
                            : t('dialogs.gone_wrong')
                    : t('dialogs.gone_wrong'),
                'error',
            );

            localStorage.clear();
            firebase.auth().signOut();
        }
    };

    return (
        <Container className="App">
            <Suspense fallback={''}>
                <GlobalSnackbar />
                <GlobalDialog />
            </Suspense>

            <GlobalStyle />

            <Router history={history}>
                <Switch>
                    <Route exact path="/">
                        <MenuPage />
                    </Route>

                    <Route exact path={['/admin/login', '/concierge/login']}>
                        <Suspense fallback={<LoadingPage />}>
                            <LoginPage />
                        </Suspense>
                    </Route>

                    <Route exact path="/admin">
                        <Redirect to="/admin/login" />
                    </Route>

                    <Route exact path="/concierge">
                        <Redirect to="/concierge/login" />
                    </Route>

                    <PrivateRoute
                        exact
                        path="/concierge/*"
                        component={() => (
                            <Suspense fallback={<LoadingPage />}>
                                <ConciergePage />
                            </Suspense>
                        )}
                    />

                    <PrivateRoute
                        exact
                        path="/admin/*"
                        render={() => (
                            <Suspense fallback={<LoadingPage />}>
                                <DashboardPage />
                            </Suspense>
                        )}
                    />

                    <FirebaseAuthProvider
                        firebase={firebase}
                        {...firebaseConfig}
                    >
                        <FirebaseAuthConsumer>
                            {({ isSignedIn, user, providerId }) => {
                                user &&
                                    user.getIdToken().then((jwtToken) => {
                                        setCustomerToken(jwtToken);
                                    });

                                if (
                                    providerId === null ||
                                    (isSignedIn && !customerProfile)
                                ) {
                                    return (
                                        <>
                                            <Route
                                                exact
                                                path={[
                                                    '/reservas',
                                                    '/reservas/login',
                                                    '/reservas/perfil/completar',
                                                ]}
                                            >
                                                <LoadingPage />
                                            </Route>
                                        </>
                                    );
                                } else if (isSignedIn && user) {
                                    if (
                                        !customerProfile.completed ||
                                        !customerProfile.email_verified
                                    ) {
                                        return (
                                            <>
                                                <Route
                                                    exact
                                                    path={[
                                                        '/reservas/login',
                                                        '/reservas',
                                                    ]}
                                                >
                                                    <Redirect to="/reservas/perfil/completar" />
                                                </Route>

                                                <Route
                                                    exact
                                                    path="/reservas/perfil/completar"
                                                >
                                                    <Suspense
                                                        fallback={
                                                            <LoadingPage />
                                                        }
                                                    >
                                                        <CustomerCompleteProfile />
                                                    </Suspense>
                                                </Route>
                                            </>
                                        );
                                    }

                                    return (
                                        <>
                                            <Route
                                                exact
                                                path={
                                                    '/reservas/perfil/completar'
                                                }
                                            >
                                                <Redirect to="/reservas" />
                                            </Route>

                                            <Route exact path="/reservas/login">
                                                <Redirect to="/reservas" />
                                            </Route>

                                            <Route exact path="/reservas">
                                                <Suspense
                                                    fallback={<LoadingPage />}
                                                >
                                                    <CustomerBookingPage />
                                                </Suspense>
                                            </Route>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <Route exact path="/reservas/login">
                                                <Suspense
                                                    fallback={<LoadingPage />}
                                                >
                                                    <CustomerLoginPage />
                                                </Suspense>
                                            </Route>

                                            <Route exact path="/reservas">
                                                <Redirect to="/reservas/login" />
                                            </Route>

                                            <Route exact path='/reservas/perfil/completar'>
                                                <Redirect to="/reservas/login" />
                                            </Route>
                                        </>
                                    );
                                }
                            }}
                        </FirebaseAuthConsumer>
                    </FirebaseAuthProvider>
                </Switch>
            </Router>
        </Container>
    );
};

export default App;
