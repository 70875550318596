/* eslint-disable max-len */
export const enUS = {
    module: {
        disabled: `Module disabled, contact Zmenu Team to enable it.`,
    },
    company_info: {
        open_now: `Open Now`,
        closed_now: `Closed Now`,
        more_info: `More info`,
    },
    dialogs: {
        attention: `Attention!`,
        success: `Success!`,
        confirm: `Yes`,
        submit: `Save`,
        edit: `Edit`,
        fail: `Ops!`,
        cancel: `Cancel`,
        confirmation: `Confirmation`,
        gone_wrong: `Something went wrong, please try again!`,
        invalid_login: `Invalid login or password, please check it and try again.`,
        not_allowed: `This user is not allowed to use this module.`,
        same_user: `An account with the email {{ email }} already exists. Try logging in with this account, rather than associating this social account.`,
    },
    login: {
        email: `Email`,
        username: `Username`,
        password: `Password`,
        submit: `Login`,
        forgot_password: `Forgotten password?`,
        signup: `Register`,
        sigin_google: `Login with Google`,
        sigin_facebook: `Login with Facebook`,
    },
    password_scores: {
        very_weak: `Very Weak`,
        weak: `Weak`,
        okay: `Okay`,
        good: `Good`,
        strong: `Strong`,
    },
    signup: {
        title: `Register`,
        complete: `Complete your profile`,
        name: `Full Name `,
        phone: `Telephone / Cell phone`,
        password: `Choose your password`,
        confirm_password: `Confirm your password`,
        submit: `Register`,
        email: `Email`,
    },
    all: `All`,
    logout: `Logout`,
    loading: `Loading`,
    footer: `made with Love by ZMenu ©`,
    dashboard: {
        title: `ZMenu Dashboard`,
        clear_filter: `Clear search & filters`,
        highlights: `Highlights`,
        not_found: `We didn't find any dish according to your search.`,
        no_dishes: `There are no dishes on the menu today`,
        seach_dish: `Search your dish`,
        search: `Search`,
        menu: {
            menu: `Menu`,
            foods: `Items`,
            categories: `Categories`,
            tags: `Tags`,
            discounts: `Discounts`,
            users: `Users`,
            profile: `Profile`,
            reader: `QR Reader`,
            company: `Company`,
            bookings: `Bookings`,
        },
        discounts: {
            title: `Discounts`,
            new: `New Discount`,
            columns: {
                name: `Name`,
                type: `Type`,
                percent: `Percent`,
                actions: `Actions`,
                period: `Period`,
                items: `Items`,
            },
            edit_title: 'Edit Discount',
            create_title: 'New Discount',
            name: 'Name',
            enabled: 'Enabled',
            percent: 'Percent %',
            period: 'Period',
            type: 'Type',
            items: 'Items (Foods / Categories)',
            types: {
                food: `Food`,
                category: `Category`,
            },
        },
        foods: {
            title: `Items List`,
            new: `New Item`,
            not_found: `Item not found, please select another and try again!`,
            image: {
                maxsize: `Exceeded maximum image size, upload a new image of maximum 5MB.`,
                error: `Something went wrong when uploading the image, please try again.`,
                remove: `Something went wrong with image removal, please try again.`,
            },
            filters: {
                search: `Search`,
                weekdays: `Weekdays`,
                periods: `Periods`,
                inclusive: `Inclusive`,
                preview: `Preview Menu`,
                categories: `Categories`,
                status: `Item Status`,
                featured: `Featured`,
                select_one_preview: `Select one to enable menu preview`,
                select_only_one_preview: `Select only one to enable menu preview`,
                select_one_order: `Select one to enable sort`,
                select_only_one_order: `Select only one to enable sort`,
            },
            weekdays: {
                all: `all`,
                sunday: `sunday`,
                monday: `monday`,
                tuesday: `tuesday`,
                wednesday: `wednesday`,
                thursday: `thursday`,
                friday: `friday`,
                saturday: `saturday`,
            },
            periods: {
                all: `all`,
                coffee: `coffee`,
                lunch: `lunch`,
                snack: `snack`,
                dine: `dinner`,
            },
            status: {
                all: `All`,
                enabled: `Enabled`,
                disabled: `Disabled`,
            },
            featured: {
                all: `All`,
                featured: `Featured`,
                not_featured: `Not Featured`,
            },
            columns: {
                name: `Name`,
                description: `Description`,
                langs: `Display Langs`,
                price: `Price`,
                categories: `Categories`,
                weekdays: `Weekdays`,
                periods: `Periods`,
                actions: `Actions`,
            },
            actions: {
                disable: `Disable`,
                enable: `Enable`,
                price: `Price Edit`,
                edit: `Edit`,
                delete: `Delete`,
            },
            dialogs: {
                enable: {
                    title: `Enable item`,
                    message: `Do you really want to enable the item '{{food_name}}' ?`,
                },
                disable: {
                    title: `Disable item`,
                    message: `Do you really want to disable the item '{{food_name}}' ?`,
                },
                price: `Edit price`,
                delete: {
                    title: `Delete item`,
                    message: `Do you really want to delete the item '{{food_name}}' ?`,
                },
            },
            form: {
                create_title: `New item`,
                edit_title: `Edit item`,
                image: `Food Image`,
                change_image: `Change Image`,
                code: `Code`,
                order: `Order`,
                enabled: `Enabled`,
                featured: `Featured`,
                price: `Price`,
                promo_price: `Promo price`,
                categories: `Categories`,
                add_category: `Add new category`,
                tags: `Tags`,
                add_tag: `Add new tag`,
                weekdays: `Weekdays`,
                periods: `Periods`,
                texts: `Item Information`,
                lang: `Lang`,
                name: `Item Name`,
                description: `Description`,
            },
        },
        categories: {
            title: `Categories List`,
            new: `New category`,
            not_found: `Category not found, please select another and try again!`,
            filters: {
                search: `Search`,
            },
            columns: {
                order: `Order`,
                name: `Name`,
                description: `Description`,
                langs: `Display Langs`,
                actions: `Actions`,
            },
            actions: {
                disable: `Disable`,
                enable: `Enable`,
                edit: `Edit`,
                delete: `Delete`,
            },
            dialogs: {
                enable: {
                    title: `Enable category`,
                    message: `Do you really want to enable the category '{{category_name}}' ?`,
                },
                disable: {
                    title: `Disable category`,
                    message: `Do you really want to disable the category '{{category_name}}' ?`,
                },
                delete: {
                    title: `Delete category`,
                    message: `Do you really want to delete the category '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `New Category`,
                edit_title: `Edit Category`,
                order: `Order`,
                enabled: `Enabled`,
                texts: `Category Information`,
                lang: `Lang`,
                name: `Category Name`,
                description: `Description`,
            },
        },
        tags: {
            title: `Tags List`,
            new: `New tag`,
            not_found: `Tag not found, please select another and try again!`,
            filters: {
                search: `Search`,
            },
            columns: {
                order: `Order`,
                name: `Name`,
                description: `Description`,
                langs: `Display Langs`,
                actions: `Actions`,
            },
            actions: {
                disable: `Disable`,
                enable: `Enable`,
                edit: `Edit`,
                delete: `Delete`,
            },
            dialogs: {
                delete: {
                    title: `Delete tag`,
                    message: `Do you really want to delete the tag '{{category_name}}' ?`,
                },
            },
            form: {
                create_title: `New Tag`,
                edit_title: `Edit Tag`,
                texts: `Tag Information`,
                lang: `Lang`,
                name: `Tag Name`,
            },
        },
        users: {
            title: `Users List`,
            new: `New user`,
            not_found: `User not found, please select another and try again!`,
            filters: {
                search: `Search`,
            },
            columns: {
                mail: `Email`,
                username: `Username`,
                fullname: `Full Name`,
                scopes: `Scopes`,
                actions: `Actions`,
            },
            actions: {
                edit: `Edit`,
                delete: `Delete`,
            },
            dialogs: {
                delete: {
                    title: `Delete user`,
                    message: `Do you really want to delete the user '{{user_name}}' ?`,
                },
            },
            form: {
                create_title: `New User`,
                edit_title: `Edit User`,
                mail: `E-mail`,
                username: `Username`,
                fullname: `Full Name`,
                password: `Password`,
                confirm_password: `Confirm Password`,
                scopes: `Scopes`,
            },
            profile: {
                title: `User Profile`,
                change_password: `Change Password`,
            },
        },
        company: {
            title: `Company`,
            time_title: `Time Info`,
            periods_title: `Periods`,
            bookings_title: `Booking Periods`,
            runnings_title: `Runnings`,
            booking_title: `Bookings`,
            colors_title: `Colors & Style`,
            disclaimers_title: `Menu Disclaimers`,
            request_error: `Unable to get company informations`,
            form: {
                name: `Name`,
                pics: `Food With Photos`,
                currency: `Currency`,
                lang: `Default Localization`,
                langs: `Available Localizations`,
                period: `Period`,
                start: `Start`,
                end: `End`,
                seats: `Seats`,
                booking_max_seats: `Booking max seats`,
                phone: `Phone Number`,
                qrcode_bg: `QR Code Background Color`,
                qrcode_border: `QR Code Border Color`,
                booking_approval: `Approve bookings`,
                booking_interval: `Interval between bookings`,
                booking_toleration: `Tolerance booking`,
                street_address: `Street Adress`,
                theme: `Theme Color`,
                timezone: `Company Timezone`,
                weekday: `Weekday`,
                law_drive: `Notice drinking and driving`,
                law_tips: `Tip notice`,
                text_tips: `Text about tip`,
            },
        },
        bookings: {
            title: `Booking List`,
            new: `New Booking`,
            delete_success: `Successfully booking canceled!`,
            created_success: `Successfully booking created!`,
            created_fail: `Could not create your booking, please try again.`,
            cancel_fail: `Could not cancel your booking, please try again.`,
            final_status: `The booking is already in its final 'Status'.`,
            no_date: `Could not load available information for selected date, please select another date and try again.`,
            not_found: `Booking not found, please select another and try again!`,
            choose_time: `Choose a time`,
            how_many: `Table for how many people?`,
            final_msg_0: `Your reservation will be on the`,
            final_msg_1: `at`,
            final_msg_2: `hrs with a table for`,
            final_msg_3: ` seats.`,
            filters: {
                search: `Search`,
                status: `Booking Status`,
            },
            weekdays: {
                sunday: `Sun`,
                monday: `Mon`,
                tuesday: `Tue`,
                wednesday: `Wed`,
                thursday: `Thu`,
                friday: `Fri`,
                saturday: `Sat`,
            },
            customer: {
                menu: `Menu`,
                title: `Bookings`,
                email: `We send you a confirmation email, check your inbox.`,
                complete: `Complete profile`,
                bookings: `Your bookings`,
                loading: `Loading...`,
                none: `You don't have any booking.`,
            },
            status: {
                all: `All`,
                created: `Waiting confirmation`,
                confirmed: `Confirmed`,
                denied: `Denied`,
                canceled: `Canceled`,
                used: `Used`,
                partial: `Partial`,
                noshow: `Not attended`,
            },
            columns: {
                customer_name: `Customer name`,
                customer_email: `Customer e - mail`,
                datetime: `Booking Date`,
                seats: `Seats`,
                status: `Status`,
                actions: `Actions`,
            },
            actions: {
                confirm: `Confirm`,
                deny: `Deny`,
                edit: `Edit`,
                delete: `Delete`,
            },
            dialogs: {
                confirmed: {
                    title: `Confirm booking`,
                    message: `Do you really want to confirm the booking for '{{booking_title}}' seats ? `,
                    success: `Booking confirmed successfully.`,
                },
                denied: {
                    title: `Deny booking`,
                    message: `Do you really want to deny the booking for '{{booking_title}}' seats ? `,
                },
                edit: `Edit booking`,
                delete: {
                    title: `Delete booking`,
                    message: `Do you really want to delete the booking for '{{booking_title}}' seats ? `,
                },
                fail: {
                    title: `Ops!`,
                    message: `Something went wrong while trying to confirm the booking, please try again later!`,
                },
            },
            concierge: {
                hello: `Hello Mr.Concierge`,
                usereader: `Use the reader below to read the booking code.`,
                openreader: `Open reader`,
                contact: `Any questions ? Please contact your manager or administrator.`,
                scancode: `Scan Booking code`,
                closereader: `Close reader`,
                loading: `Loading booking data...`,
                scanned: `Scanned booking`,
                name: `Name`,
                phone: `Phone`,
                mail: `E - mail`,
                seatsfor: `Seats for`,
                state: `State`,
                confirmed: `Confirmed people`,
                remain: `Remains`,
                cod: `Code`,
                booked: `Booked on`,
                back: `Back`,
                confirm: `Confirm Booking`,
                is_invalid: `This booking date is outside of use period.`,
                not_found: `Booking not found`,
                recomendation: `Please scan another QRCODE and try again!`,
                last_scan_id: `id: {{booking_id}}`,
            },
            form: {
                customer: `Customer`,
                datetime: `Date and time`,
                seats: `Seats`,
                status: `Status`,
            },
        },
    },
};
