import React from 'react';

import styled from 'styled-components';

export const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.55);
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
`;

const ModalBody = styled.div`
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1rem;
    height: auto;
    width: auto;
    max-width: 85vw;
    max-height: 85vh;
    border: 1px solid #ddd;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    cursor: initial;
`;

const Modal = (props) => {
    const handleCloseModal = () => {
        props.setShowModal({show: false});

        let bodyElement = document.querySelector('body');

        const scrollY = bodyElement.style.top;

        bodyElement.style.position = '';
        bodyElement.style.top = '';
        bodyElement.style.left = '';
        bodyElement.style.right = '';

        window.scrollTo(0, -parseInt(scrollY || '0'));
    };

    return (
        <Backdrop onClick={handleCloseModal}>
            <ModalBody onClick={(e) => e.preventDefault()}>
                {props.children}
            </ModalBody>
        </Backdrop>
    );
};

export default Modal;
