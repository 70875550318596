import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
    ${({size, horizontal}) =>
        !horizontal
            ? `
                margin-top: ${size};
                margin-bottom: ${size};
            `
            : `
                margin-left: ${size};
                margin-right: ${size};
                display: inline-block;
            `}
`;

const Spacer = ({size, horizontal, ...props}) => {
    switch (size) {
        case 'xs':
            size = '0.25rem';
            break;
        case 'sm':
            size = '0.5rem';
            break;
        case 'md':
            size = '1rem';
            break;
        case 'lg':
            size = '2rem';
            break;
        case 'xl':
            size = '3rem';
            break;

        default:
            size = '1rem';
            break;
    }

    return (
        <Container horizontal={horizontal} size={size}>
            {props.children}
        </Container>
    );
};

export default Spacer;
