import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const Container = styled.div`
    padding: 1rem;
    text-align: center;
    font-size: 80%;
    line-height: 200%;
    color: #444 !important;
    background: #fff;
    width: 100%;

    & > a {
        color: #000;
        text-decoration: underline;
    }
    & > p.drive_and_drink {
        font-weight: bold;
        color: #f00 !important;
    }
    & > p.tips {
        color: #444 !important;
    }
    & > p.give_tips {
        font-size: 70%;
    }
`;


const Footer = ({ notify_drink = true, disclaimers }) => {
    const { t } = useTranslation();

    const [version, setVersion] = useState('');

    useEffect(() => {
        fetch('/config.json')
            .then((res) => res.json())
            .then((data) => {
                setVersion(data.version);
            });

        // eslint-disable-next-line
    }, []);

    function Drink(props) {
        return (<>
            <p className="drive_and_drink">{disclaimers && disclaimers[0] && disclaimers[0].law_drive ? disclaimers[0].law_drive : ""}</p>
            <p className="tips">{disclaimers && disclaimers[0] && disclaimers[0].law_tips ? disclaimers[0].law_tips : ""}</p>
            <p className="give_tips">{disclaimers && disclaimers[0] && disclaimers[0].text_tips ? disclaimers[0].text_tips : ""}</p>
            <a href="https://www.zmenu.com.br" target="_blank" rel="noopener noreferrer">https://www.zmenu.com.br</a>
            <br />
        </>)
    }

    return (
        <Container>
            {notify_drink && <Drink />}
            <span>
                {t('footer')}
                {version && ` | v${version}`}
            </span>
        </Container>
    );
};

export default Footer;
