import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';


const initialState = {
    booking_max_seats: undefined,
    currency: undefined,
    lang: undefined,
    langs: [],
    modules: [],
    name: undefined,
    booking_approval: true,
    booking_interval: moment('00:30', 'HH:mm').format('HH:mm'),
    booking_toleration: moment('00:30', 'HH:mm').format('HH:mm'),
    periods: [],
    bookings: [],
    disclaimers: {},
    phone: undefined,
    pics: false,
    qrcode_bg: undefined,
    qrcode_border: undefined,
    runnings: [],
    street_address: undefined,
    theme: undefined,
    timezone: undefined,
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        update: (state, action) => {
            state.booking_max_seats = action.payload.booking_max_seats;
            state.currency = action.payload.currency;
            state.lang = action.payload.lang;
            state.langs = action.payload.langs?.slice();
            state.modules = action.payload.modules?.slice();
            state.name = action.payload.name;
            state.disclaimers = action.payload.disclaimers;
            state.booking_approval = action.payload.booking_approval;
            state.booking_interval = moment(action.payload.booking_interval, 'HH:mm').format('HH:mm');
            state.booking_toleration = moment(action.payload.booking_toleration, 'HH:mm').format('HH:mm');
            state.periods = action.payload.periods?.slice();
            state.periods && state.periods.forEach(element => {
                if (element.time) {
                    element.time = {
                        start: moment(element.time.start, 'HH:mm').format('HH:mm'),
                        end: moment(element.time.end, 'HH:mm').format('HH:mm'),
                    };
                }
            });

            state.bookings = action.payload.bookings?.slice();
            state.bookings && state.bookings.forEach(element => {
                if (element.time) {
                    element.time = {
                        start: moment(element.time.start, 'HH:mm').format('HH:mm'),
                        end: moment(element.time.end, 'HH:mm').format('HH:mm'),
                    };
                }
            });

            state.phone = action.payload.phone;
            state.pics = action.payload.pics;
            state.qrcode_bg = action.payload.qrcode_bg;
            state.qrcode_border = action.payload.qrcode_border;

            state.runnings = action.payload.runnings?.slice();
            state.runnings && state.runnings.forEach(element => {
                if (element.time) {
                    element.time = {
                        start: moment(element.time.start, 'HH:mm').format('HH:mm'),
                        end: moment(element.time.end, 'HH:mm').format('HH:mm'),
                    };
                }
            });

            state.street_address = action.payload.street_address;
            state.theme = action.payload.theme;
            state.timezone = action.payload.timezone;
        },
    },
});

// Action creators are generated for each case reducer function
export const { update } = companySlice.actions;

export default companySlice.reducer;
