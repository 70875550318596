import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import ScrollContainer from 'react-indiana-drag-scroll';

import Spacer from './../Spacer';

const MenuImage = styled.div`
    position: relative;
    background-image: url(${({ src }) => src});
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 30vh;
    min-height: 120px !important;
    max-height: 320px !important;
    ${({ isSticky }) =>
        isSticky &&
        `
        margin-bottom: 112px;

    `}
`;

const NavigationContainer = styled.div`
    width: 100%;
    max-width: calc(100vw);
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    ${({ isSticky }) =>
        isSticky &&
        `
        position: fixed;
        top: 0px;
    `}
    z-index: 1;
`;

const NavigationMenu = styled(ScrollContainer)`
    max-width: 1140px;
    width: 100%;
    height: auto;
    padding: 0.5rem 1rem;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    /* Hide Scrollbar and keep effects */
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const NavItem = styled.p`
    cursor: pointer;
    margin: 0;
    font-size: 1rem;
    color: #f4f4f4;
    padding: 12px 16px;
    border-bottom: 3px solid transparent;
    transition: 0.25s all ease-in-out;
    ${({ active }) =>
        active &&
        `
        font-weight: bold;
        color: #fff;
        border-bottom-color: #fff;
    `}
    ${({ disabled }) =>
        disabled &&
        `
        cursor: initial;
        font-weight: normal;
        color: #d3d3d3;
        border-bottom-color: transparent;
        pointer-events: none;
    `}
`;

const Row = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => (direction ? direction : 'row')};
    ${({ margin }) => margin && `margin: ${margin};`}
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    ${({ width }) => width && `width: ${width};`}
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;

const HeaderRow = styled.div`
    width: 100%;
    max-width: 1140px;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-top: 1rem;
`;

const HeaderTitle = styled.h3`
    padding: 0px;
    border: 0px;
    max-width: ${window.innerWidth > 576 ? '260px' : '0px'};
    overflow: hidden;
    white-space: nowrap;
    transition: 0.125s all ease-in-out;
    padding: 0px;
    margin: 0px;
    margin-left: 0.5rem;

    ${({ active }) =>
        active &&
        `
        max-width: 180px !important;
    `}

    @media (min-width: 576px) {
        max-width: initial !important;
    }
`;

const SearchInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
    border: 0px;
    outline: 0px;
    max-width: 0px;
    overflow: hidden;
    transition: 0.125s all ease-in-out;
    box-shadow: none;
    border-radius: 0.35rem;
    background-color: white;

    ${({ active }) =>
        active &&
        `
        margin-left: 1rem;
        padding: 0.25rem 0.5rem;
        max-width: 210px !important;
    `}
    & > svg {
        color: #8f8f8f;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        flex: 1;
    }

    & > input {
        margin-left: 1rem;
        padding: 0px;
        border: 0px;
        outline: 0px;
        transition: 0.125s all ease-in-out;
        box-shadow: none;
        border-radius: 0.25rem;
        background-color: transparent;
        width: auto;
        max-width: calc(100% - 32px);
    }
`;

const SearchButton = styled.button`
    padding: 0px;
    border: 0px;
    outline: 0px;
    box-shadow: none;
    color: white;
    background-color: transparent;
    &:focus {
        outline: none;
    }
`;

const OpenNow = styled.div`
    border-radius: 3px;
    color: ${({ active }) => (active ? 'green' : 'gray')};
    font-weight: 600;
    padding: 0.1rem 0.75rem;
    border: 1px solid #ddd;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    background-color: #fff;
`;

const RunningsItem = styled.span`
    ${({ today }) => today && `font-weight: bold;`};
`;

const CompanyBackground = styled.img`
    width: calc(100% - 2rem);
    height: auto;
    margin-bottom: 1rem;
    width: 100%;
`;

const CompanyInfoButton = styled.button`
    position: absolute;
    bottom: 0px;
    right: 0px;
    border: 0px;
    background: #f4f4f4b3;
    padding: 0.25rem 0.5rem;
    margin: 1rem;
    min-width: 64px;
    border-radius: 0.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;

const Header = ({ categories, ...props }) => {
    const { t } = useTranslation();

    const backgroundRef = useRef(null);
    const headerRef = useRef(null);
    const scrollRef = useRef(null);

    const [isSticky, setIsSticky] = useState(false);
    const [activeItem, setActiveItem] = useState(
        Object.values(categories).length > 0
            ? {
                id:
                    Object.values(categories).filter(
                        (item) => item.foods && item.foods.length > 0,
                    )[0] &&
                    Object.values(categories).filter(
                        (item) => item.foods && item.foods.length > 0,
                    )[0].category_id,
                index: 0,
            }
            : undefined,
    );
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScrollHeader);

        return () => {
            window.removeEventListener('scroll', () => handleScrollHeader);
        };

        // eslint-disable-next-line
    }, []);

    let isScrolling;

    const handleScrollHeader = () => {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(handleScrollingEnded, 100);

        if (headerRef.current && backgroundRef.current) {
            if (headerRef.current.getBoundingClientRect().top < 0) {
                setIsSticky(true);
            } else if (
                -backgroundRef.current.getBoundingClientRect().top <
                backgroundRef.current.getBoundingClientRect().height
            ) {
                setIsSticky(false);
            }
        }
    };

    const handleScrollingEnded = () => {
        let categoriesElements = document.querySelectorAll(
            '.category-title:not(.featured)',
        );

        if (categoriesElements.length === 0) {
            return;
        }

        let closestElement = categoriesElements[0];
        let closestInfo = {
            id: closestElement.getAttribute('data-id'),
            index: 0,
        };
        categoriesElements.forEach((item, index) => {
            if (
                item.getBoundingClientRect().top < window.innerHeight / 2 &&
                item.getBoundingClientRect().top >
                closestElement.getBoundingClientRect().top
            ) {
                closestElement = item;
                closestInfo = {
                    id: closestElement.getAttribute('data-id'),
                    index,
                };
            }
        });
        handleSelectNavItem(closestInfo, false);
    };

    const handleSelectNavItem = ({ id, index }, scrollTo = true) => {
        setActiveItem({ id, index });

        if (!scrollRef.current) {
            return;
        }

        let scrollElement = scrollRef.current.getElement();

        let left =
            scrollElement.childNodes[index].offsetLeft -
            scrollElement.offsetLeft;

        scrollElement.scrollTo({
            left,
            behavior: 'smooth',
        });

        if (scrollTo) {
            window.scroll({
                top:
                    document.querySelector(`.category-title[data-id="${id}"]`)
                        .offsetTop - 112,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleToggleSearch = () => {
        setShowSearch(!showSearch);
        props.handleSearch({ target: { value: '' } });
    };

    const handleShowCompanyInfo = () => {
        let bodyElement = document.querySelector('body');

        let top = window.scrollY;
        let isOpened = nowOpen();

        bodyElement.style.position = 'fixed';
        bodyElement.style.top = `-${top}px`;
        bodyElement.style.left = '0px';
        bodyElement.style.right = '0px';

        let content = (
            <>
                <Row
                    width="460px"
                    maxWidth="100%"
                    direction="column"
                    margin="auto"
                    alignItems="center"
                >
                    <CompanyBackground
                        className="company-background"
                        src="/topo_cardapio.jpg"
                    />

                    <h3>{props.companyName ? props.companyName : 'zMenu'}</h3>

                    <OpenNow active={isOpened}>
                        {isOpened
                            ? t('company_info.open_now')
                            : t('company_info.closed_now')}
                    </OpenNow>

                    <Spacer size="sm" />

                    {props.companyInfo.runnings &&
                        props.companyInfo.runnings.map(
                            (item, index) =>
                                item.time && (
                                    <React.Fragment key={`time${index}`}>
                                        <RunningsItem
                                            today={
                                                item.period ===
                                                moment()
                                                    .format('dddd')
                                                    .toLowerCase()
                                            }
                                        >
                                            {t(
                                                'dashboard.foods.weekdays.' +
                                                item.period,
                                            )}
                                            :{' '}
                                            {moment(
                                                item.time.start,
                                                'HH:mm:ss',
                                            ).format('HH:mm')}{' '}
                                            -{' '}
                                            {moment(
                                                item.time.end,
                                                'HH:mm:ss',
                                            ).format('HH:mm')}
                                        </RunningsItem>
                                    </React.Fragment>
                                ),
                        )}

                    <Spacer size="sm" />

                    <p>
                        {t('dashboard.company.form.street_address')}:{' '}
                        {props.companyInfo.street_address}
                    </p>

                    <p>
                        {t('dashboard.company.form.phone')}:{' '}
                        {props.companyInfo.phone}
                    </p>
                </Row>
            </>
        );

        props.setShowModal({
            show: true,
            content,
        });
    };


    const hasCompanyInfos =
        props.companyInfo &&
        (props.companyInfo.runnings ||
            props.companyInfo.phone ||
            props.companyInfo.street_address);

    const nowOpen = () => {
        const now = moment();

        if (props.companyInfo && props.companyInfo.runnings) {
            let period = props.companyInfo.runnings.find(item => item.period === moment().format('dddd').toLowerCase())
            let start = moment(period.time.start, "HH:mm:ss");
            let end = moment(period.time.end, "HH:mm:ss");

            if (period.time.end < period.time.start) {
                end.add(1, 'days')
            }
            return now.isBetween(start, end, 'second'); // false
        }
    };

    return (
        <>
            <MenuImage
                ref={backgroundRef}
                src="/topo_cardapio.jpg"
                alt="Imagem responsiva"
                isSticky={isSticky}
                className="menu-banner"
            >
                {hasCompanyInfos && (
                    <CompanyInfoButton
                        onClick={handleShowCompanyInfo}
                        className="more-info-button"
                    >
                        {t('company_info.more_info')}
                    </CompanyInfoButton>
                )}
            </MenuImage>
            <NavigationContainer
                className={`header-container ${!isSticky ? 'sticky' : 'floating'
                    }`}
                ref={headerRef}
                isSticky={isSticky}
            >
                <HeaderRow>
                    {(isSticky || window.innerWidth > 576) &&
                        (window.innerWidth < 576 ? !showSearch : true) && (
                            <img
                                height="32"
                                width="auto"
                                alt="Company Logo"
                                className="company-logo"
                            />
                        )}

                    <Row margin={!isSticky ? 'auto' : 'initial'}>
                        <HeaderTitle
                            active={!showSearch}
                            className="company-title"
                        >
                            <span onClick={scrollToTop}>
                                {props.companyName
                                    ? props.companyName
                                    : 'zMenu'}
                            </span>
                        </HeaderTitle>
                    </Row>

                    <Row
                        margin={
                            showSearch && window.innerWidth > 992
                                ? 'auto 0 auto -300px'
                                : 'initial'
                        }
                    >
                        <SearchInput
                            active={showSearch}
                            className="search-container"
                        >
                            <i className="fas fa-search input-search-icon" />
                            <input
                                type="text"
                                name="search"
                                placeholder={t('dashboard.search_dish')}
                                onChange={props.handleSearch}
                                value={props.searchValue}
                                className="search-input"
                            />
                        </SearchInput>
                        <SearchButton
                            onClick={handleToggleSearch}
                            className={
                                showSearch ? 'cancel-button' : 'search-button'
                            }
                        >
                            {showSearch ? (
                                'Cancelar'
                            ) : (
                                <div>
                                    <i className="fas fa-search button-search-icon" />
                                </div>
                            )}
                        </SearchButton>
                    </Row>
                </HeaderRow>

                <NavigationMenu
                    ref={scrollRef}
                    className="navigation-container"
                >
                    {categories.length === 0 ? (
                        <NavItem
                            key={0}
                            active={true}
                            className="nav-item active"
                        >
                            Todos
                        </NavItem>
                    ) : (
                        categories.map((category, index) => (
                            <NavItem
                                key={category.category_id}
                                active={category.category_id === activeItem.id}
                                onClick={() =>
                                    handleSelectNavItem({
                                        id: category.category_id,
                                        index,
                                    })
                                }
                                className={`nav-item ${category.category_id === activeItem.id
                                    ? 'active'
                                    : ''
                                    }`}
                            >
                                {category.name}
                            </NavItem>
                        ))
                    )}
                </NavigationMenu>
            </NavigationContainer>
        </>
    );
};

export default Header;
