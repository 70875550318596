import React from 'react';

import styled, {keyframes} from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.div`
    border: ${({size}) => size || 8}px solid #f2f2f2;
    border-top: ${({size}) => size || 8}px solid
        ${({color}) => (color ? color : 'var(--primary-color)')};
    border-radius: 50%;
    width: ${({size}) => (size || 8) * 8}px;
    height: ${({size}) => (size || 8) * 8}px;
    animation: ${spin} 1.5s linear infinite;
    margin-bottom: 1rem;
`;

function Loader(props) {
    return (
        <Container className="loading-spinner-container">
            <Spinner color={props.color} className="loading-spinner" />
        </Container>
    );
}

export default Loader;
