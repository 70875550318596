import {ptBR} from './pt-BR';
import {enUS} from './en-US';
import {esES} from './es-ES';
import {itIT} from './it-IT';

export const resources = {
    pt: {translation: ptBR},
    en: {translation: enUS},
    es: {translation: esES},
    it: {translation: itIT},
};
